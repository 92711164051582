import React, {useEffect, useRef, useState} from "react";
import {
    SafeAreaView,
    View,
    Text,
    Image,
    Dimensions,
    StyleSheet,
    Modal,
    ScrollView,
    Pressable,
  } from "react-native";
import { FontAwesome } from '@expo/vector-icons';
import { FontAwesome6 } from '@expo/vector-icons';
import DatePicker from "../components/DateTimePicker";
import { getCurrentDate, getOneMonthBackDate } from "../components/common/utils/date-time-conversions";
import { ChargerAttributes } from "../models/charger-model";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
//import { getAllChargers, getMyChargers } from "../store/actions/charging-actions";
import { PrepareApiProcedure } from "../components/common/utils/prepare-api-procedure";
import { fetchDataFromAPI } from "../services/api-requests";
import { getMyChargers } from "../store/actions/charging-actions";
import { notificationService } from "../services/notification-service";
import { Tooltip } from 'react-native-elements';
import { color } from "react-native-elements/dist/helpers";
import { blue } from "react-native-reanimated/lib/typescript/reanimated2/Colors";
import MyChargerInfoCard from "./MyChargerInfoCard";
import DateFilterModal from "../components/modals/DateFilterModal";
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { getMyChargerHistory } from "../services/charger-history-service";
import { ChargerHistoryAttributes } from "../models/charger-history-model";
import MyChargerHistoryAtAGlance from "./MyChargingHistoryAtAGlance";
import ChargeHistoryCard from "./ChargeHistoryCard";
import { AppDispatch } from "../store";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

const { width, height } = Dimensions.get("window");

export default function MyChargers({navigation, route}) {

    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [customDates, setCustomDates] = useState<{ start: string | null, end: string | null }>({
        start: getOneMonthBackDate(),
        end: getCurrentDate(),
    });
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [totalKwH, setTotalKwh] = useState(0);
    const myChargersStore: ChargerAttributes[] = useSelector((state: RootState) => state.chargers.myChargers);

    const dispatch: AppDispatch = useDispatch();

    const [filteredChargers, setFilteredChargers] = React.useState<ChargerHistoryAttributes[]>([]);
    const [myChargerHistory, setMyChargerHistory] = React.useState<ChargerHistoryAttributes[]>([]);
    const[chargerAssociation, setChargerAssociation] = useState<ChargerAttributes[] | undefined>([])

    const [view, setView] = useState<'list' | 'history'>('list');

    const handleSetSelectedOption = (option: any) => {
        setSelectedOption(option);
    }

    const historySumWithDateRange = (history: ChargerHistoryAttributes[]) => {
        let total_charge_price = 0;
        let total_kwh_calc = 0;

        history.forEach((h) => {
            const start = dayjs(h.charging_start_time);
            const end = dayjs(h.charging_end_time);
            const rangeStart = dayjs(customDates.start);
            const rangeEnd = dayjs(customDates.end).add(1, 'day').startOf('day');

            // Check if the charger's date is within the range
            if (start.isBetween(rangeStart, rangeEnd, null, '[]') && end.isBetween(rangeStart, rangeEnd, null, '[]')) {
                total_charge_price += h.total_charge_price ?? 0;
                total_kwh_calc += h.total_kwh ?? 0;
            }
        })

        return {
            total_charge_price: total_charge_price.toFixed(2),
            total_kwh_calc: total_kwh_calc.toFixed(1)
        }
    }

    const fetchAndFormatChargerData = async () => {
        try {
            // Fetch charger histories
            const myChargerHistoriesData: ChargerHistoryAttributes[] = await getMyChargerHistory();

            const { total_charge_price, total_kwh_calc } = historySumWithDateRange(myChargerHistoriesData);

            const formattedChargers = myChargerHistoriesData.map((history) => {
                // Format updatedAt date
                 const isoDateString = String(history.tblCharger?.updatedAt);
                 const date = new Date(isoDateString);
                //const date = history.tblCharger?.updatedAt;
                const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;

                return {
                    ...history,
                    updatedAt: date,
                    formattedDate,
                };
            });

            setTotalKwh(total_kwh_calc);
            setTotalRevenue(total_charge_price);
            setMyChargerHistory(formattedChargers);
        } catch (e) {
            console.error("Error getting my charger histories", e);
            //notificationService.sendNotification('error', 'You are not logged in! Please log in and try again');
        }
    };

    const retrieveChargingHistory = async () => {
        //const association = myChargerHistory.map((ch: ChargerHistoryAttributes) => myChargersStore?.find((c) => c.uuid === ch.chargerId));
        
        const association = myChargerHistory.map((ch: ChargerHistoryAttributes) => {
            const matchedCharger = myChargersStore?.find((c) => c.uuid === ch.chargerId);
            console.log(`Matching chargerId: ${ch.chargerId} with uuid in store: ${matchedCharger?.uuid}`);
            return matchedCharger;
        });
        setChargerAssociation(association);
    }

    const filterByDateRange = (startDate, endDate) => {
        return myChargerHistory.filter(item => {
            const chargingStartTime = dayjs(item.tblCharger?.updatedAt); // Parse the datetime string from API
            const start = dayjs(startDate, 'MM/DD/YYYY'); // Parse the start date string
            const end = dayjs(endDate, 'MM/DD/YYYY').add(1, 'day').startOf('day'); // Parse the end date string, add one day so that the entire 'end' day is counted
    
            return chargingStartTime.isSameOrAfter(start) && chargingStartTime.isBefore(end);
        });
    };

    React.useEffect(() => {
        fetchAndFormatChargerData();
        dispatch(getMyChargers());
    }, []);

    // Populate the charger association once myChargers come in
    React.useEffect(() => {
        retrieveChargingHistory();
    }, [myChargersStore]);

    React.useEffect(() => {
        const filteredData = filterByDateRange(customDates.start, customDates.end);
        setFilteredChargers(filteredData);
        const { total_charge_price, total_kwh_calc } = historySumWithDateRange(filteredData);
        setTotalKwh(total_kwh_calc);
        setTotalRevenue(total_charge_price);
    }, [myChargerHistory])

    // React.useEffect(() => {
        
    // }, [filteredChargers]);

    

    useEffect(() => {
        const applyFilter = (filteredData: ChargerHistoryAttributes[]) => {
            setFilteredChargers(filteredData);
    
            const { total_charge_price, total_kwh_calc } = historySumWithDateRange(filteredData);
    
            setTotalKwh(total_kwh_calc);
            setTotalRevenue(total_charge_price);
        };

        if (filteredChargers.length > 0) {
            const filteredData = filterByDateRange(customDates.start, customDates.end);
            applyFilter(filteredData);
        }
    }, [filteredChargers]);

    const filterDate = () => {
        let filteredChargerList;

        filteredChargerList = filterByDateRange(customDates.start, customDates.end);

        setFilteredChargers(filteredChargerList);  // Update state with filtered data
        setFilterModalVisible(!filterModalVisible);
        notificationService.sendNotification('success', `Date Filter Applied`);
    };

    const handleCancel = () => {
        setFilterModalVisible(!filterModalVisible);
        //fetchAndFormatChargerData();  // Reset the filteredChargers to the original list
        //notificationService.sendNotification('success', `Date Filter Removed`);
    };

    const handleShowHistoryPress = async () => {
        await fetchAndFormatChargerData();
        retrieveChargingHistory();
        setView('history');
    }

    const callGetMyChargers = route?.params?.state;

    const handleFilter = () => {
        setFilterModalVisible(!filterModalVisible)
    }

    const registerCharger = () => {
        navigation.navigate("ChargerValidation")
    }

    const editCharger = (chargerDetails:any) => {
        navigation.navigate("AddModifyCharger",{
            chargerDetails: chargerDetails,
            editCharger: true,
            state: callGetMyChargers
        })
    }

    const selectAReport = [
        { 
            label: 'Last month',
            value: '1'
        },
        {
            label: 'Custom dates',
            value: '2'
        },
    ]

    const handleCustomDatePicker = (date: string, pickerType: 'start' | 'end') => {
        setCustomDates(prev => ({ ...prev, [pickerType]: date }));
    };

    return (
        <SafeAreaView style={styles.safeArea}>
            <View style={[styles.containerBgColor, {height: height - 65, paddingBottom: 10, flex: 1}]}>
                <ScrollView>
                    <View className="p-6">
                        <MyChargerHistoryAtAGlance
                            handleFilter={handleFilter}
                            customDates={customDates}
                            filteredChargers={filteredChargers}
                            totalKwH={totalKwH}
                            totalRevenue={totalRevenue}
                        />

                        <View style={styles.line} />

                        <View>
                            <View className="flex flex-row items-center justify-between">
                                <View className="flex flex-row">
                                    <Pressable onPress={() => setView('list')}>
                                        <Text
                                            className={`text-base font-semibold ${view === 'list' ? 'text-white' : 'text-slate-400'}`}
                                            style={{ marginRight: 20 }}
                                        >
                                            Charger List
                                        </Text>
                                    </Pressable>
                                    <Pressable onPress={() => {
                                        handleShowHistoryPress();
                                    }}>
                                        <Text
                                            className={`text-base font-semibold ${view === 'history' ? 'text-white' : 'text-slate-400'}`}
                                        >
                                            Show History
                                        </Text>
                                    </Pressable>
                                </View>
                                <Pressable onPress={registerCharger}>
                                    <Text 
                                        className="px-5 py-3 text-xs font-semibold text-white rounded-full"
                                        style={styles.BgColor1}>Register a Charger
                                    </Text>
                                </Pressable>
                            </View>
                            {
                                myChargersStore && view === 'list' && myChargersStore.length > 0 ? (
                                    myChargersStore.map((data, index) => (
                                        <MyChargerInfoCard
                                            index={index}
                                            data={data}
                                            editCharger={editCharger}
                                            key={`chargerInfoCard${index}`}
                                        />
                                    ))
                                ) : (
                                    view === 'history' && (
                                        myChargerHistory.length === 0 ? (
                                            <Text
                                                className='text-base font-semibold text-white'
                                            >
                                                {`\n\nSorry, none of your chargers have any history yet!`}
                                            </Text>
                                        ) : (
                                            myChargerHistory.map((ch, index) => (
                                                <ChargeHistoryCard
                                                    index={index}
                                                    chargerHistory={ch}
                                                    charger={chargerAssociation[index]}
                                                    key={`chargeHistoryCard${index}`}
                                                />
                                            ))
                                        )
                                    )
                                )
                            }
                        </View>
                    </View>
                </ScrollView>
            </View>

            {/* modal */}
            <Modal
                animationType="slide"
                transparent={true}
                visible={filterModalVisible}
                onRequestClose={() => {
                    setFilterModalVisible(!filterModalVisible);
                }}
            >
                <DateFilterModal
                    selectedOption={selectedOption}
                    setSelectedOption={handleSetSelectedOption}
                    filterDate={filterDate}
                    handleCancel={handleCancel}
                    handleCustomDatePicker={handleCustomDatePicker}
                    customDates={customDates}
                />
            </Modal>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        backgroundColor: '#0B1E1D',
      },
    containerBgColor: {
        backgroundColor: '#0B1E1D',
    },
    BgColor: {
        backgroundColor: '#51B868'
    },
    BgColor1: {
        backgroundColor: '#39BEB780'
    },
    BgColor2: {
        backgroundColor: '#F97777'
    },
    BgColor3: {
        backgroundColor: '#39BEB7'
    },
    BgColor4:{
        backgroundColor: '#636363'
    },
    TextColor1: {
        color: '#E8E8E9'
    },
    textColor3: {
        color: "#8D8E90"
    },
    line: {
        borderBottomColor: "#636363",
        borderBottomWidth: 1,
        borderStyle: 'dashed',
        marginVertical: 20,
    },
    filterIcon: {
        height: 17,
        width: 17,
        backgroundColor: '#39BEB7',
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22,
      },
    modalView: {
        margin: 20,
        backgroundColor: "#292D33",
        borderRadius: 30,
        padding: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: 290,
        height: 250,
    },
    dropdownTrigger: {
        width: width <= 430 ? width*0.4 : 150,
    },
    dropdownContainer: {
        position: 'absolute',
        top: 30,
        left: 0,
        backgroundColor: '#fff',
        borderRadius: 5,
        marginTop: 2,
        width: width <= 430 ? width * 0.4 : 150,
        zIndex: 1000,
    },
    alignEllipses: {
        fontWeight: '500',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 150,
    },
    tooltipContainer: {
        borderRadius: 10,
        padding: 10,
        minWidth: 50,
        maxWidth: 500,
        width: 'auto',
        height: 'auto'
      }
})