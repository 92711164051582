import React, { useState } from 'react';
import { View, Text, Pressable, StyleSheet, TextInput, SafeAreaView, Dimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { createUser } from '../models/login-register-model';
import { loginUser, registerUser } from '../store/actions/user-actions';
import { notificationService } from '../services/notification-service';
import { IconButton } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '../components/MeanderStack';
import { getIpAddressFunc } from '../components/common/utils/ip-address';
import { createUserSession } from '../models/user-session-model';
import { AppDispatch } from '../store';
import { useDispatch } from 'react-redux';
import { setSession } from '../store/actions/user-session';

const { width, height } = Dimensions.get('window');

type Props = NativeStackScreenProps<RootStackParamList, 'CreateAccount'>

interface CreateAccountFormProps {
    formEmail?: string;
    navigation?: any;
    handleCreateAccount?: () => void;
  }
  

const CreateAccountForm: React.FC<CreateAccountFormProps> = ({formEmail, navigation, handleCreateAccount}) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState(formEmail ?? '');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);
    const [isPasswordVisibleConfirm, setPasswordVisibleConfirm] = useState<boolean>(false);
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        confirmEmail: '',
        password: '',
        confirmPassword: '',
    });

    const dispatch: AppDispatch = useDispatch();

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleInputChange = (field, value) => {
        let newErrors = { ...errors };
        
        switch (field) {
            case 'firstName':
              setFirstName(value);
              newErrors.name = value ? '' : 'Please enter your name.';
              break;
            case 'lastName':
              setLastName(value);
              newErrors.name = value ? '' : 'Please enter your name.';
              break;
            case 'email':
                setEmail(value);
                newErrors.email = !value ? 'Please enter your email address.' : validateEmail(value) ? '' : 'Please enter a valid email address.';
                newErrors.confirmEmail = value === confirmEmail ? '' : 'Email addresses do not match.';
                break;
            case 'confirmEmail':
                setConfirmEmail(value);
                newErrors.confirmEmail = value === email ? '' : 'Email addresses do not match.';
                break;
            case 'password':
                setPassword(value);
                newErrors.password = value ? '' : 'Please enter a password.';
                newErrors.confirmPassword = value === confirmPassword ? '' : 'Passwords do not match.';
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                newErrors.confirmPassword = value === password ? '' : 'Passwords do not match.';
                break;
            default:
                break;
        }

        setErrors(newErrors);
    };

    const signUp = async () => {
        const newErrors = { ...errors };

        if (!firstName || !lastName) newErrors.name = "Please enter your name.";
        if (!email) newErrors.email = "Please enter your email address.";
        if (!confirmEmail) newErrors.confirmEmail = "Please confirm your email address.";
        if (email !== confirmEmail) newErrors.confirmEmail = "Email addresses do not match.";
        if (!password) newErrors.password = "Please enter a password.";
        if (password !== confirmPassword) newErrors.confirmPassword = "Passwords do not match.";
        

        setErrors(newErrors);

        if (Object.values(newErrors).some(error => error !== "")) {
            return;
        }

        const registerObj = createUser({
            email: email,
            password: password,
            first_name: firstName,
            last_name: lastName
        });

        const resp = await registerUser(registerObj);

        if (resp?.length > 0) {
            notificationService.sendNotification("success", "Success! Your account is registered");
            //now log in and create a session
            const getIpAddress = await getIpAddressFunc();
            const loginObj = createUserSession({
                email: email,
                password: password,
                IpAddress: getIpAddress,
              });
            const userToken = await loginUser(loginObj);
            dispatch(setSession(userToken));
            if(handleCreateAccount) handleCreateAccount();
            setTimeout(() => {
                if(navigation) navigation.navigate('WelcomeScreen');
            }, 1500);
        } else {
            notificationService.sendNotification("error", "There was an issue while registering, please try again");
        }
    };

    const handleSubmit = () => {
        signUp();
    };

    return (
        <SafeAreaView>
            <View style={[styles.containerBgColor, { height: height}]}>
                <ScrollView>
                    <View className='p-4'>
                        <Text className='text-2xl font-bold text-center text-white'>Create an account</Text>
                        <Text style={styles.subHeaderText} className='mb-5 text-base font-normal text-center'>
                            You will be able to log in with your email in the future, using the password you set below.
                        </Text>

                        <View>
                            <Text className='pt-3 pb-2 text-base font-semibold text-white'>First Name</Text>
                            <TextInput
                                placeholder="Enter first name"
                                placeholderTextColor={'#8D8E90'}
                                style={[styles.BgColor, { borderRadius: 25 }]}
                                className="w-full p-4 text-white"
                                value={firstName}
                                onChangeText={(value: string) => handleInputChange('firstName', value)}
                            />
                            <Text className='pt-3 pb-2 text-base font-semibold text-white'>Last Name</Text>
                            <TextInput
                                placeholder="Enter last name"
                                placeholderTextColor={'#8D8E90'}
                                style={[styles.BgColor, { borderRadius: 25 }]}
                                className="w-full p-4 text-white"
                                value={lastName}
                                onChangeText={(value: string) => handleInputChange('lastName', value)}
                            />
                            {errors.name ? <Text style={styles.errorText}>{errors.name}</Text> : null}

                            <Text className='pt-3 pb-2 text-base font-semibold text-white'>Email</Text>
                            <TextInput
                                placeholder="Enter your email"
                                placeholderTextColor={'#8D8E90'}
                                style={[styles.BgColor, { borderRadius: 25 }]}
                                className="w-full p-4 text-white"
                                value={email}
                                autoCorrect={false}
                                autoComplete="new-email"
                                textContentType='none'
                                onChangeText={(value) => handleInputChange('email', value)}
                            />
                            {errors.email ? <Text style={styles.errorText}>{errors.email}</Text> : null}

                            <Text className='pt-3 pb-2 text-base font-semibold text-white'>Confirm Email</Text>
                            <TextInput
                                placeholder="Confirm your email"
                                placeholderTextColor={'#8D8E90'}
                                style={[styles.BgColor, { borderRadius: 25 }]}
                                className="w-full p-4 text-white"
                                value={confirmEmail}
                                autoCorrect={false}
                                autoComplete="off"
                                textContentType='none'
                                onChangeText={(value) => handleInputChange('confirmEmail', value)}
                            />
                            {errors.confirmEmail ? <Text style={styles.errorText}>{errors.confirmEmail}</Text> : null}

                            
                            <View>
                                <Text className='pt-3 pb-2 text-base font-semibold text-white'>Create a Password</Text>
                                <TextInput
                                    placeholder="Enter your password"
                                    placeholderTextColor={'#8D8E90'}
                                    style={[styles.BgColor, { borderRadius: 25 }]}
                                    className="w-full p-4 text-white"
                                    secureTextEntry={!isPasswordVisible}
                                    value={password}
                                    autoCorrect={false}
                                    autoComplete="new-password"
                                    textContentType='none'
                                    onChangeText={(value) => handleInputChange('password', value)}
                                />
                                <IconButton
                                    icon={(props) => (
                                        <MaterialCommunityIcons
                                        name={isPasswordVisible ? "eye" : "eye-off"}
                                        {...props}
                                        style={{ color: "#01556F" }}
                                        />
                                    )}
                                    onPress={() => setPasswordVisible(!isPasswordVisible)}
                                    style={styles.createPasswordIcon}
                                />
                            </View>

                            {errors.password ? <Text style={styles.errorText}>{errors.password}</Text> : null}

                            <Text className='pt-3 pb-2 text-base font-semibold text-white'>Confirm Password</Text>
                            <View>
                                <TextInput
                                    placeholder="Confirm your password"
                                    placeholderTextColor={'#8D8E90'}
                                    style={[styles.BgColor, { borderRadius: 25 }]}
                                    className="w-full p-4 text-white"
                                    secureTextEntry={!isPasswordVisibleConfirm}
                                    value={confirmPassword}
                                    autoCorrect={false}
                                    autoComplete="off"
                                    textContentType='none'
                                    onChangeText={(value) => handleInputChange('confirmPassword', value)}
                                />
                                <IconButton
                                    icon={(props) => (
                                        <MaterialCommunityIcons
                                        name={isPasswordVisibleConfirm ? "eye" : "eye-off"}
                                        {...props}
                                        style={{ color: "#01556F" }}
                                        />
                                    )}
                                    onPress={() => setPasswordVisibleConfirm(!isPasswordVisibleConfirm)}
                                    style={styles.createPasswordIconConfirm}
                                />
                            </View>
                            {errors.confirmPassword ? <Text style={styles.errorText}>{errors.confirmPassword}</Text> : null}

                            <Pressable onPress={handleSubmit} style={styles.button} className='p-4 mt-5 text-center rounded-full'>
                                <Text className='text-lg font-medium text-center text-white'>Create my account</Text>
                            </Pressable>
                        </View>
                    </View>
                </ScrollView>
            </View>
        </SafeAreaView>
    );
};

export default CreateAccountForm;

const styles = StyleSheet.create({
    containerBgColor: {
        backgroundColor: '#1A1D21',
    },
    subHeaderText: {
        color: '#8D8E90',
    },
    BgColor: {
        backgroundColor: '#292D33',
    },
    errorText: {
        color: '#f38787',
        fontSize: 14,
        marginTop: 4,
    },
    button: {
        backgroundColor: '#39BEB7',
    },
    createPasswordIcon: {
        position: 'absolute',
        top: 45,
        alignSelf: 'flex-end' 
    },
    createPasswordIconConfirm:{
        position: 'absolute',
        alignSelf: 'flex-end'
    }
});


