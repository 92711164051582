import { createUserSession, UserSessionAttributes } from '../models/user-session-model';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getIpAddressFunc } from '../components/common/utils/ip-address';
import { useDispatch } from 'react-redux';
import { setSession } from '../store/actions/user-session';
import { loginUser, oauthLogin, registerUser, validateUserToken } from '../store/actions/user-actions';
import { userActions } from '../store/slices/user-slice';
import store from "../store"; 

export const getUserSessionObj = async (): Promise<UserSessionAttributes> => {

    const token =  await AsyncStorage.getItem("token");
    const IpAddress = await AsyncStorage.getItem("IpAddress");
    const email = await AsyncStorage.getItem("email");
    const UserId = await AsyncStorage.getItem("userId");
    let createSession;
    let sessionObj;
    if(token !== null && IpAddress !== null && email !== null && UserId !== null){
        sessionObj = createUserSession({
            access_token: token, 
            IpAddress: IpAddress, 
            email: email, 
            id: UserId, 
        })
        //store.dispatch(setSession(createSession))
    } else {
        //not logged in, set the guest login
        const ip = await getIpAddressFunc();
        if(!ip) {ip = "1.1.1.1"}
        createSession = createUserSession({
            email: "guest",
            password: "meanderev-rocks!",
            IpAddress: ip,
            loginType: true,
            access_token: "",
        });

        //get the token
        //store.dispatch(userActions.loginUser({userLogin: createSession}));
        //store.dispatch(setSession(sessionObj))
        sessionObj = await loginUser(createSession);
    }
    
    return sessionObj;
}

/**
 * Stores the user session object in AsyncStorage
 * @param userSession - Object containing user session data based on UserSessionAttributes
 */
export const storeUserSession = async (userSession: UserSessionAttributes): Promise<void> => {
    try {
      // Store the user session object as a JSON string in AsyncStorage
      await AsyncStorage.setItem('userSession', JSON.stringify(userSession));
      console.log('User session stored successfully');
    } catch (error) {
      console.error('Error storing user session: ', error);
    }
  };

  /**
 * Retrieves the user session object from AsyncStorage and recreates it as a UserSessionAttributes object
 * @returns UserSessionAttributes | null - Returns the user session or null if not found
 */
export const getUserSession = async (): Promise<UserSessionAttributes | null> => {
    try {
      const sessionData = await AsyncStorage.getItem('userSession');
      if (sessionData !== null && sessionData !== 'undefined') {
        // Parse the retrieved data and recreate the UserSessionAttributes object
        let parsedSession: UserSessionAttributes = JSON.parse(sessionData);
        if(parsedSession.msg === "Invalid token" || parsedSession.msg === 'Session not found'){
            //invalid session, we need to create a new one
            await AsyncStorage.clear();
            const ip = await getIpAddressFunc();
            const createSession = createUserSession({
                email: "guest",
                password: "meanderev-rocks!",
                IpAddress: ip,
                loginType: true,
                access_token: "",
            })
            parsedSession = await loginUser(createSession);
        }
        return createUserSession(parsedSession); // Use createUserSession to handle missing fields
      } else {
        //not logged in, set the guest login
        var ip = await getIpAddressFunc();
        if(!ip) {ip = "1.1.1.1"}
        const createSession = createUserSession({
            email: "guest",
            password: "meanderev-rocks!",
            IpAddress: ip,
            loginType: true,
            access_token: "",
        });
        const sessionObj = await loginUser(createSession);
        return createUserSession(sessionObj);
      }
    } catch (error) {
      console.error('Error retrieving user session: ', error);
      return null;
    }
  };