import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  SafeAreaView,
  Dimensions,
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Pressable,
  Platform
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { FontAwesome6 } from '@expo/vector-icons';
import { ChargerAttributes } from "../models/charger-model";
import { RootState } from "../store";
import { useSelector } from "react-redux";
import { getMyDistanceFromCharger, getMyDrivingTime } from "../store/actions/charging-actions";
import * as Location from 'expo-location';
import '@adyen/adyen-web/dist/adyen.css';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { NativeStackNavigationProp, NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../components/MeanderStack";
import { RouteProp } from "@react-navigation/native";
import SignInOptions from "./SignInOptions";
import { OrderAttributes } from "../models/order-model";
import { UserSessionAttributes } from "../models/user-session-model";
import { getUserLocation } from "../components/common/utils/get-user-location";
import { IconButton } from "react-native-paper";
import CreateAccount from "./CreateAccount";
import CreateAccountForm from "./CreateAccountForm";

import { notificationService } from "../services/notification-service";
import { createAdyenDropin, createPayment, getPaymentMethods, openPaymentMethods } from "../services/payments-service";
import { createOrder, updateOrderEmail } from "../services/orders-service";
import { checkoutCharger } from "../services/charger-service";

const { width, height } = Dimensions.get("window");

type ChargerStationNavigationProp = NativeStackNavigationProp<RootStackParamList, 'ChargerStation'>;
type ChargerStationRouteProp = RouteProp<RootStackParamList, 'ChargerStation'>;

// type Props = {
//   navigation: ChargerStationNavigationProp;
//   route: ChargerStationRouteProp;
//   searchCharger: false
// };

type ChargerStationNavigationProps = NativeStackScreenProps<RootStackParamList, 'ChargerStation'>;

export const ChargerStation: React.FC<ChargerStationNavigationProps> = ({navigation, route}) => {

  const [email, setEmail] = useState<string | null>(null);
  const [guestEmail, setGuestEmail] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [storedEmail, setStoredEmail] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [myDistanceFromCharger, setMyDistanceFromCharger] = React.useState<string>('');
  const [myTimeFromCharger, setMyTimeFromCharger] = React.useState<string>('');
  const [myLocation, setMyLocation] = React.useState<Location.LocationObjectCoords | null>(null);
  const [paymentMethods, setPaymentMethods] = React.useState<any>(null);
  const [paymentData, setPaymentData ] = React.useState<any>(null);
  const [order, setOrder ] = React.useState<OrderAttributes | null>(null);

  const [isPasswordVisible, setPasswordVisible] = React.useState<boolean>(false);

  const session: UserSessionAttributes | undefined = useSelector((state: RootState) => state.user.session);

  var hidePaymentMethods = true;
  const scrollViewRef = useRef<ScrollView>(null);

  const selectedCharger:ChargerAttributes = useSelector((state: RootState) => state.chargers.selectedCharger)

  const{lat, lng, uuid} = selectedCharger;

  const renderCharger = useMemo(() => {
    return selectedCharger;
  }, [selectedCharger]);

  const onAdyenSubmit = async (state, dropin) => {
    //console.log(JSON.stringify(state))
    let newOrder;
    if(selectedCharger.uuid){
      newOrder = await createOrder(selectedCharger.uuid)
      setOrder(newOrder);
    } else {
      notificationService.sendNotification("error", "No charger selected!");
      return;
    }
    createPayment(state.data, newOrder?.id!)
    .then(response => {
      if (response && response.status === "Authorised") {
        if (response.action) {
          // Drop-in handles the action object from the /payments response
          dropin.handleAction(response.action);
        }
        setPaymentData(response)
        togglePaymentDropin()
      } else {
        // Shouldn't get here: This only happens if we send an empty success response
        notificationService.sendNotification("error", `No response when creating payment.`);
      }
    })
    .catch(error => {
      console.error(error)
      throw Error(error);
    });
  }

  useEffect (() => {
    setEmail(guestEmail);
  }, [guestEmail])

  const handleCheckoutButton = async () => {

    if (session === undefined) {
      notificationService.sendNotification("error", "Invalid Session");
      return;
    } else if (email === null) {
      notificationService.sendNotification("error", "Please enter your email");
      return;
    } else if (!order) {
      notificationService.sendNotification("error", "Problem creating your order");
      return;
    }

    //set the order email
    await updateOrderEmail(order?.id, email);

    checkoutCharger(order?.id!, session.email === 'guest' ? email : session.email).then(() => {
      navigation.navigate('ChargingScreen', { order: order!})
    }).catch(e => {
      console.error(e);
      notificationService.sendNotification("error", `Error checking out charger: ${e}`);
    })
  }

  useEffect(() => {
    //grab the user info
    const getStorageInfo = async () => {
      const userEmail = await AsyncStorage.getItem("email");
      setStoredEmail(userEmail);
      // if(storedEmail === 'guest'){
      //   setStoredEmail(null);
      // } else {
      //   setStoredEmail(userEmail);
      // }
    }

    const fetchDistance = async () => {
      const myDistance = await getMyDistanceFromCharger(myLocation, renderCharger.uuid);
      setMyDistanceFromCharger(myDistance);
    }
    const fetchDrivingTime = async () => {
      const myTime = await getMyDrivingTime(myLocation, renderCharger.uuid);
      setMyTimeFromCharger(myTime);
    }

    const fetchPaymentMethods = async() => {
      const paymentMethodsResponse = await getPaymentMethods()
      setPaymentMethods(paymentMethodsResponse);
    }

    const requestLocation = async () => {
        const userLocation = await getUserLocation();
        setMyLocation(userLocation);
    };

    if(route?.params.searchCharger === undefined){
      setMyDistanceFromCharger(route.params.myDistanceFromCharger);
      setMyTimeFromCharger(route.params.myTimeFromCharger);

      if(!route.params.myDistanceFromCharger){
        fetchDistance();
      }
      if(!route.params.myTimeFromCharger){
        fetchDrivingTime();
      }
    }

    requestLocation();
    getStorageInfo();

    fetchPaymentMethods();

    if(session){
      setEmail(session?.email);
    }
  
  }, []);

  useEffect(() => {
    //there is something in session now, update the page states
    const getStorageInfo = async () => {
      const userEmail = await AsyncStorage.getItem("email");
      setStoredEmail(userEmail);
      setEmail(userEmail);
      // if(storedEmail === 'guest'){
      //   setStoredEmail(null);
      // } else {
      //   setStoredEmail(userEmail);
      // }
    }
    getStorageInfo();
  }, [session])

  // useEffect(() => {
  //   const fetchOrder = async(chargerId: string) => {
  //     const createOrderResponse = await createOrder(chargerId) 
  //     setOrder(createOrderResponse)
  //   }

  //   const chargerId = selectedCharger.uuid
  //   if (chargerId) {
  //     fetchOrder(chargerId)
  //   }

  // }, [selectedCharger]);

  useEffect(() => {
    if(showCreateAccount){
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollToEnd({ animated: true });
      }
    }
  }, [showCreateAccount]); // Add dependencies as needed

  useEffect(() => {
    if(paymentMethods){
      createAdyenDropin(paymentMethods, onAdyenSubmit);
    }
  }, [paymentMethods]);

  const handleOptionSelect = (value) => {
    setSelectedOption(value === selectedOption ? '' : value); // Toggle selection
  };

  const handleCreateAccount = ():void => {
    setShowCreateAccount(!showCreateAccount);
  }

  const refRBSheet = useRef();
  const OpenBottomSheet = () => {
      refRBSheet.current.open();
  }

  const handleSignIn = ():void => {
    // use the signin popup
    // TODO: make sure to save this screen and come back to it!
    OpenBottomSheet();
  }

  const togglePaymentDropin = () => {
    const dropinContainer = document.getElementById('dropin-container');
    if(dropinContainer) {
      if(hidePaymentMethods === true){
        hidePaymentMethods = false
        dropinContainer.style.display = 'block';
        openPaymentMethods();
      } else {
        hidePaymentMethods = true;
        dropinContainer.style.display = 'none';
      }
  }
}

  // useEffect(() => {
  //   const getSelectedDisTimeFromCharger = async() => {
  //     const myLocationSelectedCharger = {
  //         latitude: (lat).toFixed(1),
  //         longitude: (lng).toFixed(1)
  //     };
  //     /const myDistance = await getMyDistanceFromCharger(myLocationSelectedCharger,uuid);
  //     const myTime = await getMyDrivingTime(myLocationSelectedCharger, uuid);

  //     setMyDistanceFromCharger(myDistance);
  //     setMyTimeFromCharger(myTime)
     
  // }
  // getSelectedDisTimeFromCharger()
  // }, [selectedCharger])

  //console.log("inside chargingStation, renderCharger is:", renderCharger);

  return (
    <SafeAreaView style={styles.safeArea}>
        <View style={[styles.containerBgColor, {height: height - 65, paddingBottom: 10, flex: 1}]}>
          <ScrollView 
            ref={scrollViewRef}
          >
            <Image
              source={require("../../assets/ChargerStationBg.png")}
              style={{ aspectRatio: 1}}
              alt="Background Image"
            />
            <View className="mx-4">
                {renderCharger.isOccupied === true ?
                  (<Text 
                    className="px-5 py-2 text-xs font-bold text-white rounded-full"
                    style={[styles.BgColorNotAvailable, { width: width <= 430 ? width * 0.3 : 100, textAlign: 'center' }]}>
                      Not Available
                  </Text>) :
                  (
                    <Text 
                      className="px-5 py-2 text-xs font-bold text-white rounded-full"
                      style={[styles.BgColor1, { width: width <= 430 ? width * 0.3 : 100, textAlign: 'center' }]}>
                        Available
                    </Text>
                    )
                }
              <Text className="py-2 text-2xl font-bold text-white">{renderCharger.name}</Text>
              <Text className="text-base font-normal text-white">{renderCharger.city}, {renderCharger.state}</Text>
              <Text 
                className="py-2 text-xs font-normal text-white" 
                style={styles.textColor}>{renderCharger.full_address}</Text>
              <View className="flex flex-row items-center justify-between">
                <View className="flex flex-row gap-3">
                  <Image
                    source={require("../../assets/LocationMarker.png")}
                    style={{ height: 19, width: 19 }}
                    alt="Makers"
                  />
                  <Text className="text-white">{`${myDistanceFromCharger} miles`}</Text>
                  <Image
                    source={require("../../assets/DrivingCar.png")}
                    style={{ height: 19, width: 19 }}
                    alt="Makers"
                  />
                  <Text className="text-white">{`${myTimeFromCharger}`}</Text>
                </View>
                <View>
                <Pressable>
                  <Text 
                    className="px-5 py-2 text-xs font-semibold text-white rounded-full"
                    style={styles.BgColor2}>Get Directions</Text>
                </Pressable>
                </View>
              </View>
              <View style={styles.line}/>
              <View style={styles.BgColor3} className="p-3 rounded-lg">
                <Text className="text-base font-semibold text-white">Charger</Text>
                <View className="flex flex-row items-center gap-4 py-2">
                  <Image
                    source={require("../../assets/chargerType.png")}
                    style={{ height: 40, width: 40 }}
                    alt="ChargerType"
                  />
                  <View>
                    <Text className="text-xs font-normal text-white">
                    {`${renderCharger.manufacturer_name}-${renderCharger?.charger_type} ${renderCharger?.plug_type ?? ''} Charger`}
                    </Text>
                    <View className="flex flex-row items-center gap-2">
                      <Text className="text-base font-semibold text-white">{renderCharger.charging_capacity}kW</Text>
                      <Text className="text-xs font-normal text-white">Max power</Text>
                    </View>
                  </View>
                </View>
                <View className="flex flex-row justify-between">
                  <Text className="text-base font-semibold text-white">Peak rate</Text>
                  <Text className="text-base font-semibold text-white">{`$${renderCharger.price_hourly_peak}/hour`}</Text>
                </View>
                <View className="flex flex-row justify-between">
                  <Text className="text-base font-semibold text-white">Off-Peak rate</Text>
                  <Text className="text-base font-semibold text-white">{`$${renderCharger.price_hourly_off_peak}/hour`}</Text>
                </View>
              </View>
              <View style={styles.lin2} className="py-1"/>
              { paymentData &&

                <View>
                  <Text className="py-2 text-2xl font-bold text-white">Payment method</Text>
                  <View className="flex flex-row items-center justify-between">
                    <Pressable 
                        onPress={ () => togglePaymentDropin() }
                        style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}
                        //disabled={ !order }
                        //disabled={false}
                        // TODO: Collapse when we click outside of the payment methods view?
                        //onBlur={ () => closePaymentDropin() }
                      >
                        <Image
                          source={require("../../assets/creditCardIcon.png")}
                          style={{ height: 34, width: 50 }}
                          alt="ChargerType"
                        />
                    
                        <Text className="text-lg font-semibold text-white" style={{ textAlign: 'center', flex: 1 }}>
                          Change payment method
                        </Text>
                        <Text className="text-base text-slate-400">
                          Card ending in {paymentData.lastFour}
                        </Text>
                        <MaterialIcons name="keyboard-arrow-right" size={24} color="white" />
                    </Pressable>
                  </View>
                  <View style={styles.paymentBox} id="dropin-container"></View>
                </View>
              }
              { !paymentData &&
                <View>
                <Text className="py-2 text-2xl font-bold text-white">Payment method</Text>
                <View className="flex flex-row items-center justify-between">
                  <Pressable 
                      onPress={ () => togglePaymentDropin() }
                      style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}
                      //disabled={ !order }
                      // TODO: Collapse when we click outside of the payment methods view?
                      //onBlur={ () => closePaymentDropin() }
                    >
                    <Image
                      source={require("../../assets/creditCardIcon.png")}
                      style={{ height: 34, width: 50 }}
                      alt="ChargerType"
                    />
                    <Text className="text-lg font-semibold text-white" style={{ textAlign: 'center', flex: 1 }}>
                      Add a payment method
                    </Text>
                    <MaterialIcons name="keyboard-arrow-right" size={24} color="white" />
                  </Pressable>
                </View>
                <View style={styles.paymentBox} id="dropin-container"></View>
              </View>
              }
              
                <View style={styles.lin2} className="py-1"/>
                {(!storedEmail || storedEmail === 'guest') && !showCreateAccount &&
                  <View>
                    <Text className="py-2 text-2xl font-bold text-white">Email</Text>
                    <Text 
                      className="py-2 text-xs font-medium text-zinc-400" 
                      style={styles.textColor1}>Enter your email to receive a receipt after charging
                    </Text>
                    <TextInput 
                      placeholder="Please enter your email"
                      placeholderTextColor="#808080"
                      value={guestEmail}
                      onChangeText={setGuestEmail}
                      style={styles.BgColor6}
                      className="px-3 py-2 m-1 text-lg rounded-full"
                    />
                  </View>
                }
                {(!storedEmail || storedEmail === 'guest') &&
                  <View>
                  {/* <Pressable onPress={() => handleOptionSelect('option2')}> */}
                  <Pressable onPress={() => {
                    handleCreateAccount();
                    handleOptionSelect('checked');
                  }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center'}} className="mt-2 ml-2">
                      <View style={styles.checkBox}>
                        { 
                          selectedOption === 'checked' && 
                          <View style={{ width: 17, height: 17, }}> 
                            <FontAwesome6 name="check" size={17} color="black" />
                          </View>
                        }
                      </View>
                      <Text 
                        className="text-xs font-medium" 
                        style={styles.textColor2}
                      >
                          Create an account to check out faster next time
                      </Text>
                    </View>
                  </Pressable>
                </View>
              }
              {(!storedEmail || storedEmail === 'guest') &&
                <Pressable onPress={handleSignIn}>
                  <View className="flex flex-row items-center justify-center gap-2 mt-2 mb-4">
                    <Text className="text-xs font-medium text-white">{`Already have an account?`}</Text>
                    <Text className="text-xs font-medium" style={styles.textColorTeal}>{` Sign in`}</Text>
                    <MaterialIcons name="keyboard-arrow-right" size={16} color="white" />
                  </View>
                </Pressable>
              }
              {showCreateAccount &&
                <CreateAccountForm
                  formEmail={guestEmail || ''}
                  handleCreateAccount={handleCreateAccount}
                />
              }
            </View>
             <View style={styles.BgColor4} className="p-6">
              <Text className="text-base font-bold text-center text-slate-100">Complete checking out this charger</Text>
              <Text className="text-base font-normal text-center text-slate-100">
                  By pressing “Check Out Charger” you agree to this charger’s hourly rate above.
              </Text>
              <Pressable 
                className="mt-4" 
                onPress={handleCheckoutButton}
                disabled={!paymentData}
                >
                  <Text 
                    className="px-5 py-2 text-lg font-bold text-center text-white rounded-full" 
                    style={styles.BgColor5}>
                      Complete Checkout
                  </Text>
              </Pressable>
            </View>
          </ScrollView>
          <SignInOptions
              navigation={navigation}
              refRBSheet={refRBSheet}
              destination={'ChargerStation'}
          />
        </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#0B1E1D',
  },
  containerBgColor: {
    backgroundColor: '#0B1E1D',
  },
  BgColor1: {
    backgroundColor: '#51B868',
  },
  BgColor2: {
    backgroundColor: '#39BEB780',
  },
  BgColor3: {
    backgroundColor: '#203D3C',
  },
  BgColor4: {
    backgroundColor: '#003547',
  },
  BgColor5: {
    backgroundColor: '#3FC67D'
  },
  BgColor6: {
    backgroundColor: '#E8E8E9'
  },
  BgColor7: {
    backgroundColor: '#4F7F8B',
  },
  BgColorNotAvailable: {
    backgroundColor: '#4F7F8B',
  },
  textColor: {
    color: '#8D8E90'
  },
  textColor1: {
    color: '#D1D2D3'
  },
  textColor2: {
    color: '#A3A5A6'
  },
  textColorTeal: {
    color: '#39BEB7',
  },
  line: {
    borderBottomColor: "#438b88",
    borderBottomWidth: 1,
    borderStyle: 'dashed',
    marginVertical: 10,
  },
  lin2: {
    borderBottomColor: "#438b88",
    borderBottomWidth: 1,
    marginVertical: 10,
  },
  checkBox: {
    width: 19,
    height: 19, 
    borderWidth: 2, 
    marginRight: 8, 
    borderColor: '#E8E8E9', 
    backgroundColor: '#E8E8E9', 
    borderRadius: 4
  },
  paymentBox: {
    margin: 10
  },
  createPasswordIcon: {
    position: 'absolute',
    top: 45,
    alignSelf: 'flex-end' 
  },
  createPasswordIconConfirm:{
      position: 'absolute',
      alignSelf: 'flex-end'
  }
});

export default ChargerStation;
