import React, { useEffect } from 'react';
import { View, Image, StyleSheet, Dimensions} from 'react-native';
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import ChargerStation from "../pages/ChargingStation";
import ChargerSelection from "../pages/ChargerSelection";
import ChargerValidation from '../pages/ChargerValidation';
import AddModifyCharger from '../pages/AddModifyCharger';
import MyChargers from '../pages/MyChargers';
import DatePicker from './DatePicker';
import ChargingScreen from '../pages/ChargingScreen';
import ChargeSummary from '../pages/ChargeSummary';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import MyAccount from '../pages/MyAccount';
import AccountInfo from '../pages/AccountInfo';
import TransactionHistory from '../pages/TransactionHistory';
import CreateAccount from '../pages/CreateAccount';
import LoginWithAccount from '../pages/LoginWithAccount';
import WelcomeScreen from '../pages/WelcomeScreen';
// import SplashScreenNew from '../pages/SplashScreenNew';
import Footer from './Footer';
import { ChargerAttributes } from '../models/charger-model';
import LoginScreen from '../pages/login';
import ManageCharger from '../pages/ManageCharger';
import { ChargerHistoryAttributes } from '../models/charger-history-model';
import ChargerScanHandler from './common/ChargerScanHandler';
import { OrderAttributes } from '../models/order-model';
import { routeTitleMap } from './common/utils/route-title-map';
import ActiveChargeCard from '../pages/ActiveChargeCard';
import { ActiveChargeAttributes } from '../models/active-charges-model';

const Tab = createBottomTabNavigator();


export type RootStackParamList = {
  LoginScreen: undefined;
  Profile: { userId: string };
  ChargerScan: {
    serialNum: string,
  }
  ChargerStation: {
    myDistanceFromCharger?: string, 
    myTimeFromCharger?: string,
    searchCharger: boolean,
  };
  ChargerValidation: undefined;
  ChargeSummary: {
    charger: ChargerAttributes
    startTime: Date,
    endTime: Date
    orderId: string
  };
  AddModifyCharger:{
    unit_serial?: string,
    editCharger?: boolean,
    capacity?: number,
  };
  ChargerSelection: {
    tabSelect: 'ScanQr',
    key: string,
    title: string,
  };
  ManageChargers: {
    myChargers: ChargerAttributes[],
    myChargerHistories: ChargerHistoryAttributes[]
  };
  ChargingScreen: {
    order: OrderAttributes,
  };
  MyChargers: {
    state?: any; 
  };
  DatePicker: undefined;
  MyAccount: undefined;
  AccountInfo: {
    changePassword?: boolean,
  };
  TransactionHistory: undefined;
  CreateAccount: {
    formEmail?: string,
  };
  LoginWithAccount: undefined;
  Footer: undefined;
  WelcomeScreen: undefined;
  ActiveChargeCard: undefined;

};

const Stack = createNativeStackNavigator<RootStackParamList>();

const MeanderStack = ({ currentRouteName }: { currentRouteName: string }) => {
  return (

  <Stack.Navigator
    initialRouteName="WelcomeScreen"
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen 
      name="LoginScreen" 
      component={LoginScreen}
      options={{
        title: currentRouteName && routeTitleMap[currentRouteName] ? routeTitleMap[currentRouteName].title : 'MeanderEV',
      }}
    />
    <Stack.Screen 
      name="ChargerScan" 
      component={ChargerScanHandler}
      options={{
        title: currentRouteName && routeTitleMap[currentRouteName] ? routeTitleMap[currentRouteName].title : 'MeanderEV',
      }} 
    />
    <Stack.Screen 
      name="ChargerStation" 
      component={ChargerStation}
      options={{
        title: currentRouteName && routeTitleMap[currentRouteName] ? routeTitleMap[currentRouteName].title : 'MeanderEV',
      }} 
    />
    <Stack.Screen 
      name="ChargerSelection" 
      component={ChargerSelection}
      options={{
        title: currentRouteName && routeTitleMap[currentRouteName] ? routeTitleMap[currentRouteName].title : 'MeanderEV',
      }} 
    />
    <Stack.Screen 
      name="ChargerValidation" 
      component={ChargerValidation}
      options={{
        title: currentRouteName && routeTitleMap[currentRouteName] ? routeTitleMap[currentRouteName].title : 'MeanderEV',
      }} 
    />
    <Stack.Screen 
      name="AddModifyCharger" 
      component={AddModifyCharger}
      options={{
        title: currentRouteName && routeTitleMap[currentRouteName] ? routeTitleMap[currentRouteName].title : 'MeanderEV',
      }} 
    />
    <Stack.Screen 
      name="MyChargers" 
      component={MyChargers}
      options={{
        title: currentRouteName && routeTitleMap[currentRouteName] ? routeTitleMap[currentRouteName].title : 'MeanderEV',
      }} 
    />
    <Stack.Screen 
      name="DatePicker" 
      component={DatePicker}
      options={{
        title: currentRouteName && routeTitleMap[currentRouteName] ? routeTitleMap[currentRouteName].title : 'MeanderEV',
      }} 
    />
    <Stack.Screen 
      name="ChargingScreen" 
      component={ChargingScreen}
      options={{
        title: currentRouteName && routeTitleMap[currentRouteName] ? routeTitleMap[currentRouteName].title : 'MeanderEV',
      }} 
    />
    <Stack.Screen 
      name="ChargeSummary" 
      component={ChargeSummary}
      options={{
        title: currentRouteName && routeTitleMap[currentRouteName] ? routeTitleMap[currentRouteName].title : 'MeanderEV',
      }} 
    />
    <Stack.Screen 
      name="MyAccount" 
      component={MyAccount}
      options={{
        title: currentRouteName && routeTitleMap[currentRouteName] ? routeTitleMap[currentRouteName].title : 'MeanderEV',
      }} 
    />
    <Stack.Screen 
      name="AccountInfo" 
      component={AccountInfo}
      options={{
        title: currentRouteName && routeTitleMap[currentRouteName] ? routeTitleMap[currentRouteName].title : 'MeanderEV',
      }} 
    />
    <Stack.Screen 
      name="TransactionHistory" 
      component={TransactionHistory}
      options={{
        title: currentRouteName && routeTitleMap[currentRouteName] ? routeTitleMap[currentRouteName].title : 'MeanderEV',
      }} 
    />
    <Stack.Screen 
      name="CreateAccount" 
      component={CreateAccount}
      options={{
        title: currentRouteName && routeTitleMap[currentRouteName] ? routeTitleMap[currentRouteName].title : 'MeanderEV',
      }} 
    /> 
    <Stack.Screen 
      name="LoginWithAccount" 
      component={LoginWithAccount}
      options={{
        title: currentRouteName && routeTitleMap[currentRouteName] ? routeTitleMap[currentRouteName].title : 'MeanderEV',
      }} 
    />
    <Stack.Screen 
      name="WelcomeScreen" 
      component={WelcomeScreen} 
      options={{ 
        title: currentRouteName && routeTitleMap[currentRouteName] ? routeTitleMap[currentRouteName].title : "Welcome to MeanderEV" 
      }}
    />
    <Stack.Screen 
      name="ManageChargers" 
      component={ManageCharger}
      options={{
        title: currentRouteName && routeTitleMap[currentRouteName] ? routeTitleMap[currentRouteName].title : 'MeanderEV',
      }} 
    />
    <Stack.Screen 
      name="ActiveChargeCard" 
      component={ActiveChargeCard}
    />
    {/* <Stack.Screen name="SplashScreenNew" component={SplashScreenNew} /> */}
    <Stack.Screen name="Footer" component={Footer} />
  </Stack.Navigator>

  );
};

export default MeanderStack;