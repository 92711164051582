import { getDateInUtcSeconds } from "../components/common/utils/date-time-conversions";
import { PrepareApiProcedure } from "../components/common/utils/prepare-api-procedure";
import { ChargerAttributes } from "../models/charger-model";
import { fetchDataFromAPI } from "./api-requests";

export const checkinCharger = async (orderId: string) => {
    //check in the charger
    const apiQuery = PrepareApiProcedure(
        `charger/checkin/${orderId}`,
        'POST', 
        'charger', 
        '');
    return await fetchDataFromAPI(apiQuery);
}

export const  checkoutCharger = async (orderId: string, email: string) => {
    //Check out the charger using the checkout API
    const d = new Date().getTimezoneOffset();
    const apiQuery = PrepareApiProcedure(`charger/checkout/${orderId}`, 
                                            'POST', 
                                            'charger', 
                                            {tz_offset: d,
                                              email: email
                                            });
  
    let checkedOut = await fetchDataFromAPI(apiQuery);
    // TODO: Check the response for success here
}
  

export const getChargerStatus = async (charger: ChargerAttributes) => {
    try {
        const apiQuery = PrepareApiProcedure(`charger/getChargerCarData/${charger.uuid}`, 'GET', 'charger', '');
        const chargerStatus = await fetchDataFromAPI(apiQuery);
        return chargerStatus;
    } catch (error) {
        console.log(error);
    }
}

export const validateCharger = async (serialNumber: string) => {
    try {
        const apiQuery = PrepareApiProcedure(`charger/validate/${serialNumber}`, 'GET', 'charger', '');
        const validCharger = await fetchDataFromAPI(apiQuery);
        return validCharger;
    } catch (error) {
        console.log(error);
    }
}