import React, { useState, useRef, useEffect } from "react";
import {
  SafeAreaView,
  View,
  Button,
  Text,
  Image,
  Dimensions,
  TextInput,
  Pressable,
  StyleSheet,
  Platform,
  Modal,
  Alert,
} from "react-native";
//import MapView from 'expo';
import RBSheet from "react-native-raw-bottom-sheet";
import { ScrollView } from "react-native-gesture-handler";
import { NavigationProp } from "@react-navigation/native";
import * as Location from 'expo-location';
import MapScreen from "./MapScreen";
import { FontAwesome6 } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { useDispatch, useSelector } from "react-redux";
import { ChargerAttributes, createEmptyCharger } from "../models/charger-model";
import { RootState } from "../store";
import { notificationService } from "../services/notification-service";
import { getMyDistanceFromCharger, getMyDrivingTime, updateSelectedCharger } from "../store/actions/charging-actions";
import { chargerActions } from "../store/slices/charger-slice";
import { AppDispatch } from "../store";

interface TimeAndDistance {
  chargerId: string;
  myDistance: string;
  myTime: string;
}

export default function FindChargerMap({ 
    navigation, 
    myLocation, 
    onMapInteraction
  }) {
  const [modalVisible, setModalVisible] = useState(false);
  
  const [selectCharger, setSelectCharger] = useState<boolean>(false);

  const { width: windowWidth, height: windowHeight } = Dimensions.get("window");

  const refRBSheet = useRef();

    const dispatch: AppDispatch = useDispatch();
    const [selectedChargers, setSelectedChargers] = React.useState<ChargerAttributes[]>([]);
    //const [myLocation, setMyLocation] = React.useState<Location.LocationObjectCoords | null>(null);
    const [query, setQuery] = useState('');
    const [myTimeAndDistance, setMyTimeAndDistance] = React.useState<TimeAndDistance[]>([]);

    const chargerList: ChargerAttributes[] = useSelector(
        (state: RootState) => state.chargers.chargers
    ) as ChargerAttributes[];

    const storedSelectedCharger: ChargerAttributes = useSelector(
        (state: RootState) => state.chargers.selectedCharger
    ) as ChargerAttributes

    const dropdownSet = Array.isArray(chargerList) && chargerList.length > 0 ?
                    chargerList.map((charger) => {
        return(
            {
                id: charger.uuid.toString(),
                title: `${charger.unit_serial}: ${charger.name}`,
                charger: charger
            }
        );
    }) : [];

    const filteredChargers = Array.isArray(dropdownSet) && dropdownSet.length > 0 ? 
                        dropdownSet.filter(
        (charger) =>
            charger &&
            charger.charger.unit_serial &&
            charger.charger.name &&
            (charger.charger.unit_serial.toLowerCase().includes(query?.toLowerCase()) ||
            charger.charger.name.toLowerCase().includes(query?.toLowerCase()))
    ).slice(0, 5) : [];

  const chargingDetails = (boolChargeDetails: any) => {
    refRBSheet.current.close();
    navigation.navigate("ChargerDetails", {
      noCharger: boolChargeDetails,
    });
  };

  const hideBottomSheet = () => {
    setSelectCharger(false)
  }

  // React.useEffect(() => {
  //   const requestLocation = async () => {
  //       try {
  //           const { status } = await Location.requestForegroundPermissionsAsync();

  //           if (status === 'granted') {
  //               const location = await Location.getCurrentPositionAsync({});
  //               setMyLocation(location.coords);
  //           } else {
  //               Platform.OS === 'web' ? '' :
  //               notificationService.sendNotification('error', 'Location permission denied');
  //           }
  //       } catch (error) {
  //           notificationService.sendNotification('error', `Error requesting location permission: ${error}`);
  //       }
  //   };
  //   requestLocation();
  // }, []);

  const markerOpenBottomSheet = (charger: ChargerAttributes) => {
    console.log('chargerList:', charger)
    //selected a charger from the map view!
    let newChargerList: ChargerAttributes[] = [];
    chargerList.forEach( (c: ChargerAttributes) => {
      if(c.lat === charger.lat && c.lng === charger.lng){
        newChargerList.push(c);
      }
    })
    setSelectedChargers(newChargerList);
    setSelectCharger(true);
    refRBSheet.current.open();
  }

  const markerCloseBottomSheet = () => {
    setSelectCharger(false);
    refRBSheet.current.close();
  }

  useEffect(() => {

    console.log('selectedChargers:', selectedChargers)
    const fetchMyTimeAndDistance = async () => {
      //we don't need to run this query multiple times...it's the same location!
      const myDistance = await getMyDistanceFromCharger(myLocation, selectedChargers[0].uuid)
      const myTime = await getMyDrivingTime(myLocation, selectedChargers[0].uuid)
      const timeAndDistance: TimeAndDistance[] = selectedChargers?.map((c) => {
        return{
          chargerId: c.uuid,
          myDistance: myDistance ?? "-",
          myTime: myTime ?? "-"
        }
      })

      console.log('selectedChargersTest:', timeAndDistance)
      setMyTimeAndDistance(timeAndDistance);
    }

    if (selectedChargers.length > 0){
      fetchMyTimeAndDistance();
    }
    
  }, [selectedChargers])
  

  return (
    <SafeAreaView style={[styles.container, {height: windowHeight-65}]}>
      <View
        style={[
          styles.container,
          {
            width: windowWidth < 430 ? windowWidth : 430,
            height: windowHeight - 65, paddingBottom: 60,
            position: "relative",
          },
        ]}
      >
        <View style={{ flex: 1 }}>
          <MapScreen 
            markerOpenBottomSheet={markerOpenBottomSheet}
            markerCloseBottomSheet={markerCloseBottomSheet}
            myInputLocation={myLocation}
            selectCharger={selectCharger}
            onMapInteraction={onMapInteraction}
          />
        </View>

        {/* Bottom Sheet */}
      </View>
      {/* <View></View> */}
      <View style={{ display: "flex", justifyContent: "center" }}>
        <RBSheet
          ref={refRBSheet}
          height={360}
          duration={250}
          closeOnDragDown={true}
          closeOnPressMask={true}
          onClose={hideBottomSheet}
          customStyles={{
            container: {
              borderRadius: 30,
              backgroundColor: "#1A1D21",
              width: windowWidth <= 430 ? windowWidth*0.9 : 400,
              position: "relative",
              display: "flex",
              alignSelf: "center",
              paddingBottom: 70,
              marginBottom: 80
            },
          }}
        >   
        {
          selectedChargers.length === 1 ? (
            <View>
            <View style={{ flex:1, alignItems: 'center' }}>
                <Image 
                    source={require('../../assets/headerBarIcon.png')} 
                    style={{ width: 55, height: 5, marginTop: 12 }}
                />
            </View>
            <View className="p-4">
                <View className="flex flex-row items-center gap-2">
                    <Image 
                        source={{uri: 'https://us.123rf.com/450wm/pekosman/pekosman2308/pekosman230816231/211592972-charging-an-electric-car-in-the-city-close-up.jpg?ver=6'}}
                        style={{ width: 90, height: 90, borderRadius: 12 }}
                    />
                    <View>
                        <Text className="text-lg font-bold text-white">{`${selectedChargers[0].name}`}</Text>
                        <Text className="text-sm font-normal" style={{color:'#8D8E90'}}>{`${selectedChargers[0]?.address ?? ''}`}</Text>
                    </View>
                </View>
                <View style={styles.line} className="my-4"/>
                <View className="flex flex-row items-center justify-between">
                    <View>
                    { !selectedChargers[0].isOccupied &&
                        <Text 
                            className="px-5 py-2 text-xs font-bold text-white rounded-full"
                            style={{ textAlign: 'center', backgroundColor: '#51B868' }}>
                              Available
                        </Text>
                      } 
                      { selectedChargers[0].isOccupied &&
                        <Text 
                          className="px-5 py-2 text-xs font-bold text-white rounded-full"
                          style={{ textAlign: 'center', backgroundColor: '#4F7F8B' }}>
                            Not Available
                        </Text>
                      }
                    </View>
                    <View className="flex flex-row items-center gap-2">
                        <FontAwesome6 name="location-dot" size={24} color="#8D8E90" />
                        <Text style={{color: '#8D8E90'}}>{`${myTimeAndDistance[0]?.myDistance} mi`}</Text>
                        <FontAwesome5 name="car" size={24} color="#8D8E90" />
                        <Text style={{color: '#8D8E90'}}>{`${myTimeAndDistance[0]?.myTime}`}</Text>
                    </View>
                </View>
                <View style={styles.line} className="my-4"/>
                <View className="flex flex-row items-center gap-4 py-2">
                  <Image
                    source={require("../../assets/chargerType.png")}
                    style={{ height: 40, width: 40 }}
                    alt="ChargerType"
                  />
                  <View>
                    <Text className="text-xs font-normal text-white">
                      {`${selectedChargers[0]?.manufacturer_name}-${selectedChargers[0]?.charger_type} ${selectedChargers[0]?.plug_type ?? ''} Charger`}
                    </Text>
                    <Text className="text-lg font-semibold text-white">{`$${selectedChargers[0]?.price_hourly_peak}/hr (pk)`}</Text>
                  </View>
                </View>
                <View className="flex flex-row justify-between mt-2">
                    <Pressable 
                        style={styles.btn1}>
                        <Text className="font-medium text-white">Get Directions</Text>
                    </Pressable>
                    <Pressable 
                      style={styles.btn2}
                      onPress={() => {
                        console.log("clicked view charger", selectedChargers[0])
                        dispatch(chargerActions.updateSelectedCharger({selectedCharger: selectedChargers[0]}));
                        markerCloseBottomSheet();
                        navigation.navigate("ChargerStation", {
                          myDistanceFromCharger: myTimeAndDistance[0].myDistance,
                          myTimeFromCharger: myTimeAndDistance[0].myTime
                        });
                      }}
                    >
                        <Text 
                          className="font-medium text-white"
                        >
                          View Charger
                        </Text>
                    </Pressable>
                </View>
            </View>
            </View>
          ) : (
            <View >
              <View style={{ flex:1, alignItems: 'center' }}>
                  <Image 
                      source={require('../../assets/headerBarIcon.png')} 
                      style={{ width: 55, height: 5, marginTop: 12 }}
                  />
              </View>
              <View className="p-4">
                <ScrollView style={{height: 320}}>

                  {
                    selectedChargers.map((item, index) => {
                      return (
                        <View>
                          <View className="flex flex-row items-center justify-between">
                            <View>
                              <Image 
                                source={require('../../assets/stationPlugIn.png')} 
                                style={{ width: 30, height: 38}}
                                className="ml-2"
                              />
                            </View>
                            <View>
                              <Image
                                  source={require("../../assets/chargerType.png")}
                                  style={{ height: 40, width: 40 }}
                                  alt="ChargerType"
                                />
                            </View>
                            <View>
                              <Text className="text-xs font-normal" style={styles.textColor}>{item.name}</Text>
                              <Text className="text-base font-semibold text-white"><FontAwesome name="dollar" size={16} color="#fff" />{`${item.price_hourly_peak}/hour (pk)`}</Text>
                              <View className="flex flex-row items-center gap-1">
                                <FontAwesome6 name="location-dot" size={17} color="#8D8E90" />
                                <Text style={{color: '#8D8E90'}}>{`${myTimeAndDistance[index]?.myDistance} mi`}</Text>
                                <FontAwesome5 name="car" size={17} color="#8D8E90" />
                                <Text style={{color: '#8D8E90'}}>{`${myTimeAndDistance[index]?.myTime}`}</Text>
                              </View>
                            </View>
                            <View>
                              <Pressable 
                                style={styles.btn2}
                                onPress={() => {
                                  console.log("clicked view charger", item)
                                  dispatch(chargerActions.updateSelectedCharger({selectedCharger: item}));
                                  markerCloseBottomSheet();
                                  navigation.navigate("ChargerStation", {
                                    myDistanceFromCharger: myTimeAndDistance[index].myDistance,
                                    myTimeFromCharger: myTimeAndDistance[index].myTime
                                  });
                                }}
                              >
                                  <Text 
                                    className="font-medium text-white"
                                  >
                                    View Charger
                                  </Text>
                              </Pressable>
                            </View>
                          </View>
                          {
                            index < selectedChargers.length -1 && (
                              <View style={styles.line} className="my-4"/>
                            )
                          }
                          
                        </View>
                      )
                    })
                  }
                  
                </ScrollView>
              </View>
            </View>
          )
        }
        
        </RBSheet>
      </View>

      {/* Modal Code */}
      {/* <View>
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalVisible(!modalVisible);
          }}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={styles.alignCrossIcon}>
                <Pressable onPress={() => setModalVisible(!modalVisible)}>
                  <Image
                    source={require("../images/crossModal.png")}
                    alt="current"
                    style={{ width: 15, height: 15 }}
                  />
                </Pressable>
              </View>
              <Text style={styles.modalText}>
                This charger is not available for charging. Do you still want to
                check the information?
              </Text>
              <Pressable
                style={[styles.button, styles.buttonClose]}
                onPress={() => {
                  setModalVisible(!modalVisible);
                  chargingDetails(true);
                }}
              >
                <Text style={styles.textStyle}>Yes</Text>
              </Pressable>
              <Pressable
                style={[styles.chargerStationBtn]}
                onPress={() => {
                  setModalVisible(!modalVisible);
                  navigation.navigate("ChargerStation");
                  refRBSheet.current.close();
                  
                }}
              >
                <Text style={styles.textStyle}>View Charger</Text>
              </Pressable>
            </View>
          </View>
        </Modal>
      </View> */}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    marginTop: 0,
    marginBottom: 0,
  },
  cardContainer: {
    backgroundColor: "#073B39",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    borderTopWidth: 5,
    borderColor: "#102928",
    borderLeftWidth: 5,
    borderRightWidth: 5,
    paddingBottom: 70,
  },
  map: {
    width: 525,
    height: 525,
    borderRadius: 15,
  },
  inputControl:
    Platform.OS === "web"
      ? {
          top: 12,
          height: 34,
          fontSize: 12,
          color: "#073A37",
          borderWidth: 5,
          borderRadius: 22,
          backgroundColor: "#a2d9d5",
          width: "100%",
          alignSelf: "center",
          borderColor: "#a2d9d5",
          shadowColor: "green",
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.2,
          shadowRadius: 2,
          elevation: 2,
          padding: 10,
        }
      : {
          top: 12,
          height: 34,
          fontSize: 12,
          color: "#073A37",
          borderWidth: 5,
          borderRadius: 22,
          backgroundColor: "#a2d9d5",
          width: "90%",
          alignSelf: "center",
          borderColor: "#a2d9d5",
          shadowColor: "green",
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.2,
          shadowRadius: 2,
          elevation: 2,
          padding: 10,
        },

  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "#102928",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: 276,
    height: 181,
  },
  button: {
    borderRadius: 10,
    elevation: 2,
    paddingTop: 5,
    width: 77,
    height: 32,
  },
  chargerStationBtn:{
    borderRadius: 10,
    elevation: 2,
    paddingTop: 6,
    width: 100,
    height: 35,
    marginTop: 10,
    backgroundColor: "#10F4E9",
    fontSize: 12
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#10F4E9",
  },
  textStyle: {
    color: "#184C4C",
    fontWeight: "500",
    textAlign: "center",
    fontFamily: "Poppins",
    marginTop: 3,
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
    color: "#FFFFFF",
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: "500",
  },
  alignCrossIcon: {
    position: "absolute",
    top: 15,
    left: 245,
  },
  line: {
    borderBottomColor: "#636363",
    borderBottomWidth: 1,
    borderStyle: 'dashed',
  },
  btn1: {
    borderColor: '#8D8E90',
    borderWidth: 2,
    paddingHorizontal:24,
    paddingVertical: 12,
    borderRadius: 30
  },
  btn2: {
    backgroundColor: '#39BEB7', 
    borderRadius: 30, 
    paddingHorizontal:8, 
    paddingVertical: 12
  },
  textColor: {
    color: '#E8E8E9'
  }
});
