import React, {useEffect, useState} from "react";
import {
    SafeAreaView,
    View,
    Text,
    Image,
    Dimensions,
    StyleSheet,
    TextInput,
    ScrollView,
    Pressable,
  } from "react-native";
const { width, height } = Dimensions.get("window");
import { Entypo } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootStackParamList } from "../components/MeanderStack";
import { RouteProp } from "@react-navigation/native";
import { getDateString, getTimeString } from "../components/common/utils/date-time-conversions";
import { getChargerHistoryByTime } from "../services/charger-history-service";
import { calculateTimeFromEmporiaOutput } from "../components/common/utils/peak-off-peak-field";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getLastFour } from "../services/payments-service";

type ChargeSummaryNavigationProp = NativeStackNavigationProp<RootStackParamList, 'ChargeSummary'>;
type ChargeSummaryRouteProp = RouteProp<RootStackParamList, 'ChargeSummary'>;

type Props = {
    navigation: ChargeSummaryNavigationProp,
    route: ChargeSummaryRouteProp,
}

export const ChargeSummary = ({navigation, route}) => {
    // const selectedCharger: ChargerAttributes = useSelector(
    //     (state: RootState) => state.chargers.selectedCharger
    // ) as ChargerAttributes
    //pass the charger in by route, 
    //  as this charge summary screen will also be generated from transaction history
    //const charger:ChargerAttributes = route?.params.charger;
    const [chargerHistory, setChargerHistory] = useState<any | null>(null);
    const [userEmail, setUserEmail] = useState<string | null>('');
    const [pt, setPt] = useState<number>(0);
    const [opt, setOpt] = useState<number>(0);
    const [lastFourCard, setLastFourCard] = useState<any>({lastFour: ''});
    const {charger, startTime, endTime, orderId} = route?.params
    //const startTime = getDateFromUtcSeconds(1716806822);
    //const endTime = getDateFromUtcSeconds(1716829802);

    // console.log("got charger:", charger);
    // console.log("start time: ", getDateInUtcSeconds(startTime));
    // console.log("endTime:", getDateInUtcSeconds(endTime));
    
    //grab the charger history
    // 'api/charger/chargerHistoryByTime'
    useEffect (() => {
        const getHistory = async () => {
            const history = await getChargerHistoryByTime(charger, startTime, endTime);
            if (history?.message === "Did not find any activity for this time period") {
                //set everything on the screen to 0, or a message about invalid charging period
            } else {
                setChargerHistory(history);
            }
        }

        const getUserEmail = async () => {
            const email = await AsyncStorage.getItem('email');
            setUserEmail(email);
        }

        const cardLastFour = async () => {
            const lastFour = await getLastFour(orderId);
            setLastFourCard(lastFour);
        }

        getHistory();
        getUserEmail();
        cardLastFour();
    }, [])
    
    //once we have all the charger history data, parse it for the output
    useEffect (() => {
        //determine peak/off-peak durations and final cost here
        // we have all the data from the charger at this point
        if (chargerHistory){
            const {peakTime, offPeakTime} = calculateTimeFromEmporiaOutput(chargerHistory);
            setPt(peakTime);
            setOpt(offPeakTime);
        }
    }, [chargerHistory])

    const getChargingPrice = () => {
        if(chargerHistory){
            return (
                (pt*chargerHistory.meanderData[0].price_basis_peak/3600) +
                (opt*chargerHistory.meanderData[0].price_basis_off_peak/3600)
            );
        } else {
            return 0;
        }
    }

    const getLocalTaxPrice = () => {
        // put in an API here to get the local tax at the charger location
        //returning 6.9% for now
        return 0.069 * getChargingPrice();
    }

    const getTotalPrice = () => {
        return (
            getChargingPrice() + getLocalTaxPrice()
        );
    }
    
    return (
        <SafeAreaView style={styles.safeArea}>
            <View style={[styles.containerBgColor, {height: height - 65, paddingBottom: 10, flex: 1}]}>
                <ScrollView>
                    <View className="p-4">
                            <Text className="text-base font-semibold text-white">Your Charge Summary</Text>
                            <View className="flex flex-row items-center gap-3 pb-3 mt-3 ml-0 rounded-2xl" style={styles.BgColor1}>
                                <Image
                                    source={require("../../assets/RegisterBgImage.png")}
                                    style={{ width: 100, height: 100, borderRadius: 12 }}
                                    alt="Station Image"
                                />
                                <View style={styles.address}>
                                    <Text className="text-lg font-bold text-white">
                                        {`${charger.name}`}
                                    </Text>
                                    <Text className="text-sm font-normal" style={styles.textColor}>
                                        {`${charger.full_address}`}
                                    </Text>
                                </View>
                            </View>

                            <View className="mt-4">
                                <Text className="mb-3 text-base font-semibold text-white">Charger</Text>
                                <View className="flex flex-row items-center gap-4">
                                    <Image
                                        source={require("../../assets/chargerType.png")}
                                        style={{ width: 32, height: 32 }}
                                        alt="Station Image"
                                    />
                                    <View>
                                        <Text style={styles.textColor2} className="text-xs font-normal">
                                            {`${charger.charger_model} - ${charger.charger_type} J1772 Charger`}
                                        </Text>
                                        <View className="flex flex-row items-center">
                                            <Text className="text-lg font-semibold text-white">
                                                {`${charger.charging_capacity} kW`}
                                            </Text>
                                            <Entypo name="dot-single" size={24} color="#8D8E90" />
                                            <Text style={styles.textColor}>
                                                power
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View className="flex flex-row items-center justify-between mt-3">
                                    <Text className="text-base font-semibold text-white">Peak rate</Text>
                                    <Text className="text-base font-semibold text-white">
                                        <FontAwesome name="dollar" size={15} color="white" className="mr-1"/>
                                        {`${charger.price_hourly_peak.toFixed(2)}`}
                                    </Text>
                                    <Text className="text-base font-semibold text-white">Off-Peak rate</Text>
                                    <Text className="text-base font-semibold text-white">
                                        <FontAwesome name="dollar" size={15} color="white" className="mr-1"/>
                                        {`${charger.price_hourly_off_peak.toFixed(2)}`}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.line}/>

                            <View>
                                <View className="flex flex-row items-center justify-between mt-2 mb-2">
                                    <Text style={styles.textColor}>Booking Date</Text>
                                    <Text className="text-sm font-normal text-white">{`${getDateString(startTime)}`}</Text>
                                </View>
                                <View className="flex flex-row items-center justify-between mt-2 mb-2">
                                    <Text style={styles.textColor}>Time of Arrival</Text>
                                    <Text className="text-sm font-normal text-white">{`${getTimeString(startTime)}`}</Text>
                                </View>
                                <View className="flex flex-row items-center justify-between mt-2 mb-2">
                                    <Text style={styles.textColor}>Total Charging Duration</Text>
                                    <Text className="text-sm font-normal text-white">{`${((pt+opt)/3600).toFixed(2)} hours`}</Text>
                                </View>
                                <View className="flex flex-row items-center justify-between mt-2 mb-2">
                                    <Text style={styles.textColor}>Peak Rate Charging Duration</Text>
                                    <Text className="text-sm font-normal text-white">{`${(pt/3600).toFixed(2)} hours`}</Text>
                                </View>
                                <View className="flex flex-row items-center justify-between mt-2 mb-2">
                                    <Text style={styles.textColor}>Off-Peak Rate Charging Duration</Text>
                                    <Text className="text-sm font-normal text-white">{`${(opt/3600).toFixed(2)} hours`}</Text>
                                </View>
                                <View className="flex flex-row items-center justify-between mt-2 mb-2">
                                    <Text style={styles.textColor}>Charging Cost</Text>
                                    <Text className="text-sm font-normal text-white">
                                        <FontAwesome name="dollar" size={13} color="white" className="mr-1"/>
                                        {`${getChargingPrice().toFixed(2)}`}
                                    </Text>
                                </View>
                                <View className="flex flex-row items-center justify-between mt-2 mb-2">
                                    <Text style={styles.textColor}>Tax</Text>
                                    <Text className="text-sm font-normal text-white">
                                        <FontAwesome name="dollar" size={13} color="white" className="mr-1"/>
                                        {`${getLocalTaxPrice().toFixed(2)}`}
                                    </Text>
                                </View>
                                {/* Total cost */}
                                <View className="flex flex-row items-center justify-between p-4 mt-2 mb-2 rounded-full" style={styles.BgColor1}>
                                    <Text style={styles.textColor}>Total cost</Text>
                                    <Text className="text-sm font-normal text-white">
                                        <FontAwesome name="dollar" size={13} color="white" className="mr-1"/>
                                        {`${getTotalPrice().toFixed(2)}`}
                                    </Text>
                                </View>
                            </View>

                            <View className="mt-1">
                                <Text className="text-base font-semibold text-white">Payment Method</Text>
                                <View className="flex flex-row items-center gap-4 mt-1 mb-1">
                                    <Image
                                        source={require("../../assets/wallet.png")}
                                        style={{ width: 32, height: 32 }}
                                        alt="Station Image"
                                    />
                                    <Text className="text-lg font-semibold text-white">{`Card ending in ${lastFourCard.lastFour}`}</Text>
                                </View>
                            </View>

                            <View style={styles.line}/>
                            {(!userEmail || userEmail === 'guest') &&
                            <View>
                                <Text className="text-base font-semibold text-white">Create an account</Text>
                                <Text className="text-sm font-semibold" style={styles.textColor3}>
                                    Save time on your next charge and save your charging history by saving your information and creating account
                                </Text>
                                <View className="mt-3 mb-6">
                                    <Text style={styles.textColor3} className="mt-2 mb-2 text-sm font-semibold">Email Address</Text>
                                    <TextInput 
                                        placeholder="Enter your email"
                                        placeholderTextColor={"#1A1D21"}
                                        style={styles.BgColor3}
                                        className="px-3 py-2 m-1 text-lg rounded-full"
                                    />

                                    <Text style={styles.textColor3} className="mt-2 mb-2 text-sm font-semibold">Create your password</Text>
                                    <TextInput 
                                        placeholder="**********"
                                        placeholderTextColor={"#1A1D21"}
                                        style={styles.BgColor3}
                                        className="px-3 py-2 m-1 text-lg rounded-full"
                                    />

                                    <Text style={styles.textColor3} className="mt-2 mb-2 text-sm font-semibold">Confirm your password</Text>
                                    <TextInput
                                        placeholder="**********"
                                        placeholderTextColor={'#1A1D21'}
                                        style={styles.BgColor3}
                                        className="px-3 py-2 m-1 text-lg rounded-full"
                                    />

                                    <Pressable className="mt-6">
                                    <Text 
                                        className="px-5 py-2 text-lg font-bold text-center text-white rounded-full" 
                                        style={styles.BgColor4}>
                                        Create account
                                    </Text>
                                    </Pressable>
                                </View>
                            </View>
                            }
                    </View>
                </ScrollView>
            </View>
        </SafeAreaView>
    )
}

export default ChargeSummary;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        backgroundColor: '#1A1D21',
      },
    containerBgColor: {
        backgroundColor: '#1A1D21',
    },
    BgColor1: {
        backgroundColor: '#292D33'
    },
    BgColor2: {
        backgroundColor: '#234D4E'
    },
    BgColor3: {
        backgroundColor: '#E8E8E9'
    },
    BgColor4: {
        backgroundColor: '#4F7F8B'
    },
    line: {
        borderBottomColor: "#636363",
        borderBottomWidth: 1,
        borderStyle: 'solid',
        marginVertical: 20,
    },
    textColor: {
        color: '#8D8E90'
    },
    textColor2: {
        color: '#E8E8E9'
    },
    textColor3: {
        color: '#D1D2D3'
    },
    address: {
        width: width <= 430 ? width * 0.5 : 250,
    }
})

