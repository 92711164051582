import React from 'react';
import { View, Button, Platform, Image, Text, Pressable} from 'react-native';
// import { GoogleSignin, statusCodes } from '@react-native-google-signin/google-signin';
import { makeRedirectUri, useAuthRequest, ResponseType } from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import { createUserSession } from '../models/user-session-model';
import { loginUser } from '../store/actions/user-actions';

WebBrowser.maybeCompleteAuthSession();

export default function GoogleAuthButton({
  navigation, 
  RBRef,
  destination}) {

  const redirectUri = makeRedirectUri({
    useProxy: true, // For managed workflow, useProxy enables easier setup
    //path: process.env.REACT_NATIVE_OAUTH2_GOOGLE_INIT_URL,
    //native: 'http://app.meanderev.co:8803/api/oauth2/callback'
    path: 'https://app.meanderev.co:8803/api/oauth2/callback/web'
    //scheme: 'meanderapp',
    //path: '/api/oauth2/callback'
  });
  
  const [request, response, promptAsync] = useAuthRequest(
    {
      clientId: process.env.REACT_NATIVE_GOOGLE_OAUTH2_WEB_CLIENT,
      responseType: ResponseType.Code,
      scopes: ['email', 'profile'],
	    redirectUri,
    },
     {authorizationEndpoint: `https://app.meanderev.co:8803/api/oauth2/init/web`}
  );

  React.useEffect(() => {
    console.log("Got a response: ", response)
    if(response?.type === 'dismiss'){
      RBRef.current.close();
    }
  

    async function fetchLoginAPIs(response:any) {
      if (response?.type === 'success') {
        const { code } = response.params;
        console.log('Authorization: ', response.params);
        
        const loginObj = createUserSession({
          email: "guest",
          password: "meanderev-rocks!",
          IpAddress: "27.123.249.248",
        });
        const resp = await loginUser(loginObj);

        if (resp) {
          const userInfo = {
            name: "guest",
            email: "guest",
            password: "meanderev-rocks!",
          };
          navigation.navigate(destination, {
            userInfo: userInfo,
            auth: "NormalLogin",
          });
        } else {
          console.log("error retrieving the login token");
        }

      }
    }

    fetchLoginAPIs(response);
  }, [response]);

  return (
    <Pressable
        disabled={!request}
        onPress={() => {
          promptAsync({useProxy: true})
      }}
      className='rounded-full'
      >
        <View className="flex-row justify-center items-center bg-white rounded-full py-3 px-4 my-1">
          <Image
            source={require("../../assets/googleIcon.png")}
            style={{
              width: 24,
              height: 24,
            }}
          />
          <Text className="className='text-black text-lg font-medium ml-4">
            Continue with Google
          </Text>
        </View>
    </Pressable>
    );
}
