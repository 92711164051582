import React, { useRef, useState, useEffect } from 'react';
import { 
    View, 
    StyleSheet, 
    SafeAreaView, 
    Dimensions, 
    ScrollView, 
    Text, 
    Image, 
    Pressable, 
    Platform, 
    Linking 
} from 'react-native';
import { Video, ResizeMode } from 'expo-av';
import { 
    getActiveCharges, 
    loginUser, 
    oauthLogin, 
    registerUser, 
    validateUserToken 
} from '../store/actions/user-actions';
import { notificationService } from '../services/notification-service';
import { createUserSession, UserSessionAttributes } from '../models/user-session-model';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import SignInOptions from './SignInOptions';
import { getIpAddressFunc } from '../components/common/utils/ip-address';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { UserInfo } from '../models/user-info';
import { createUser } from '../models/login-register-model';
import { setSession } from '../store/actions/user-session';
import { getAllChargers } from '../store/actions/charging-actions';
import { storeUserSession, getUserSession, getUserSessionObj } from '../services/user-session-service';
import { AppDispatch } from '../store';
import ActiveChargeCard from './ActiveChargeCard';

const { width, height } = Dimensions.get('window');

const WelcomeScreen = ({navigation, route}:any) => {

    const [ipAddress, setIpAddress] = useState<string | null>("");
    const [userToken, setUserToken] = useState<string>("");
    const [userSession, setUserSession] = useState<UserSessionAttributes | undefined>(undefined)
    let navDestination = 'MyChargers'
    const session: UserSessionAttributes | undefined = useSelector((state: RootState) => state.user.session);
    const dispatch: AppDispatch = useDispatch();

    const refRBSheet = useRef();
    const OpenBottomSheet = () => {
        if(refRBSheet && refRBSheet.current){
            refRBSheet.current.open();
        }
    }

    const logout = route?.params?.token;

    useEffect(() => {
        console.log("Loaded splash screen");
        const getUserIp = async () => {
          const getIpAddress = await getIpAddressFunc();
          console.log("IP address retrieved: ", getIpAddress);
          setIpAddress(getIpAddress);
        };
    
        const isUserSignedIn = async () => {
            const token = await AsyncStorage.getItem("token");
            if(token){
                setUserToken(token);
                const newUserSession = await validateUserToken();
                dispatch(setSession(newUserSession));
                await storeUserSession(newUserSession);
            } else {
                //clear the session
                await AsyncStorage.clear();
            }
        }

        const getSessionObj = async () => {

            const createSession = await getUserSession();
            if(createSession){
                setUserSession(createSession);
                dispatch(setSession(createSession));
            }
        }

        getUserIp();
        isUserSignedIn();
        getSessionObj();
        dispatch(getActiveCharges());

        //window event listener for web flow
        const oauthReturnMessage = async(event: MessageEvent) => {
          if(event.origin !== "https://app.meanderev.co:8803") {
              return;
          }
    
          const profile: UserInfo = event.data as UserInfo
    
          const getIpAddress = await getIpAddressFunc();
          const { sub, email, firstname, lastname } = profile;
    
          // Login with google credentials
          
          const loginObj = createUserSession({
            email: email,
            password: sub.toString(),
            IpAddress: getIpAddress,
            loginType: true
          });
    
          const resp = await loginUser(loginObj);
    
          if(resp === null){
            const registerObj = createUser({
              email: email,
              password:sub.toString(),
              username: email,
              first_name: firstname,
              last_name: lastname,
              primary_use: 'guest',
              role: 'Guest'
          })
          
          refRBSheet?.current.close();
          const resp = await registerUser(registerObj);
    
          if (resp?.length > 0) {
            
              const loginObjAfterRegister = createUserSession({
                email: email,
                password: sub.toString(),
                IpAddress: getIpAddress,
              });
    
              const resp = await loginUser(loginObjAfterRegister);
              if (resp?.length > 0) {
                navigation.navigate("ChargerSelection", {
                  userInfo: loginObj,
                  auth: "googleOAuth",
                });
                notificationService.sendNotification("success", "Login Successful!");
              } else {
                console.log("error retrieving the login token");
              }
            } else {
              notificationService.sendNotification("error", "There was an issue while registering, please try again");
            }
          }
          else{
            await storeUserSession(resp);
            dispatch(setSession(resp));
            navigation.navigate(navDestination, {
              userInfo: loginObj,
              auth: "googleOAuth",
            });
          }
    
          //const {service, sub, email, firstname, lastname, picture} = event.data;
          
          dispatch(oauthLogin(profile));
          //close the popup
          if(event.data.closeWindow) {
              window.close();
              //update the user profile in store
          }
          
        };
    
        //TODO: web flow
        //add window event listener for web flow
        window.addEventListener('message', oauthReturnMessage);
        if (Platform.OS !== "web"){
            //TODO: mobile flow
            // Define the event parameter type using TypeScript
            console.log("Deep linking now...");
            const handleDeepLink = (event: { url: string }) => {
            console.log("Deep link received:", event.url);
            };
            // Subscribe to the deep link event
            const subscription = Linking.addEventListener("url", handleDeepLink);
        
            // Get the initial deep link if the app was opened by one
            // Linking.getInitialURL().then((url) => {
            //   if (url) {
            //     console.log("App was opened with URL:", url);
            //   }
            // });
        }
        // Return a cleanup function that removes the event listener
        return () => {
            if (Platform.OS !== "web"){
                // Remove the event listener using the subscription object
                subscription.remove();
            }
    
          //TODO: web flow
          //remove window event listener for web flow
          window.removeEventListener('message', oauthReturnMessage);
        };
      }, []);

      useEffect(() => {
        const validate = async () => {
            const resp = await validateUserToken();
            if(resp.msg !== "ok"){
                await clearStorage();
            } else {
                console.log("User token validation:", resp.msg);
                //get the chargers too
                dispatch(getAllChargers());
            }
            return resp;
        }

        const clearStorage = async () => {
            //await AsyncStorage.clear();
        }

        if(ipAddress !== "" && userToken !== "" && userToken !== null && ipAddress !== null) {
            validate();
        }
    }, [ipAddress, userToken]);

    const handleManagePress = async () => {
        console.log("manage EV pressed")
        //check if user already logged in
        //if user is not logged in, bring to sign in/sign up page
        // if they are logged in, bring to "my chargers" page
        navDestination = "MyChargers";
        if(userToken && userSession?.email !== 'guest'){
            const resp = await validateUserToken();
            await storeUserSession(resp);
            //now the session object should be in resp
            if (!resp || resp.msg !== "ok"){
                notificationService.sendNotification('error', 'You are not logged in!');
                // clear the cache
                const keys = await AsyncStorage.getAllKeys();
                await AsyncStorage.multiRemove(keys);
                OpenBottomSheet();
            } else {
                navigation.navigate(navDestination);
            }
        } else {
            notificationService.sendNotification('error', 'You are not logged in!');
            OpenBottomSheet();
        }
    }

    const handleNavPress = async (tabSelect: string) => {
        const createSession = await getUserSession();
        if(createSession && createSession.access_token !== ''){
            setUserSession(createSession);
            navigation.navigate("ChargerSelection", {tabSelect: tabSelect});
        } else {
            notificationService.sendNotification("error", "Error Creating Session");
        }
    }

    const { width: windowWidth, height: windowHeight } = Dimensions.get('window');

    // Max size for the video to prevent it from being too large
    const maxVideoWidth = windowWidth <= 1080 ? windowWidth : 1080;
    const maxVideoHeight = windowHeight * .75; // Or any proportion that fits your design

    return (
        <SafeAreaView style={styles.container}>
            <View style={[styles.containerBgColor, { height: height, width: width <= 430 ? width : 430 }]}>
                <ScrollView contentContainerStyle={styles.scrollContainer}>
                    <View style={styles.videoContainer}>
                        <Video
                            //source={require('../../assets/splashVideo.mp4')} //video URL
                            //source={require('../../assets/splashScreenHD.mov')}
                            source={require('../../assets/meanderstars1440.mp4')}
                            style={[styles.video, { width: maxVideoWidth, height: maxVideoHeight }]}
                            //resizeMode={ResizeMode.COVER}
                            resizeMode={ResizeMode.CONTAIN}
                            rate={1.0}
                            shouldPlay
                            isLooping
                            isMuted
                            useNativeControls={false}
                        />
                    </View>
                    <View style={styles.overlay}>
                            
                        <View style={styles.textBgColor}>
                            <Image source={require('../../assets/mountainSplash.png')} style={styles.mountainSize}/>
                            <Image source={require('../../assets/chargingStation.png')} style={styles.chargingStation}/>
                            <View className='p-3'>
                                <Text className='text-2xl font-bold text-center text-white'>Start charging immediately or easily find EV charging stations around you</Text>
                                <Text className='pt-1 text-sm font-normal text-center text-white'>Scan a nearby charger’s QR code to begin charging immediately or find a charger near you.</Text>
                                <View className='flex flex-row justify-between pt-4'>
                                    <Pressable
                                        style={styles.chargerStationBtn}
                                        onPress={() => {
                                            handleNavPress('ScanQr');
                                        }}
                                    >
                                        <Text style={styles.textStyle}>
                                            Scan QR code
                                        </Text>
                                    </Pressable>
                                    <Pressable
                                        style={styles.chargerStationBtn1}
                                        onPress={() => {
                                            handleNavPress('Map');
                                        }}
                                    >
                                        <Text style={styles.textStyle1}>
                                            Find a charger
                                        </Text>
                                    </Pressable>
                                </View>
                                <Pressable 
                                onPress={handleManagePress}>
                                    <Text className='py-3 text-sm font-normal text-center text-white' style={styles.textStyle2}>
                                        Manage a charger
                                    </Text>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </ScrollView>
                <SignInOptions
                    navigation={navigation}
                    refRBSheet={refRBSheet}
                    destination={'MyChargers'}
                />
            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    containerBgColor: {
        backgroundColor: '#1A1D21',
        flex: 1,
    },
    scrollContainer: {
        flexGrow: 1,
    },
    videoContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    video: {
        position: 'absolute',
        // top: 0,
        // left: 0,
        // bottom: 0,
        // right: 0,
        width: '100%',
        height: undefined,
        //aspectRatio: 1080 / 1920,
        aspectRatio: 1400 / 1400,
        alignSelf: 'center',
        resizeMode: 'contain',
        transform: [
            { scale: .7 },
            { translateY: -300 },
            { translateX: -0 },
            { rotate: '0deg'},
        ],
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        color: '#FFFFFF',
        fontSize: 24,
        fontWeight: 'bold',
        position: 'absolute'
    },
    mountainSize: {
        width: '100%',
        height: '100%',
        resizeMode: 'contain',
        position: 'absolute',
        bottom: 210
    },
    chargingStation: {
        top: 0,
        left: '50%',
        width: 40,
        height: 90,
    },
    textBgColor: {
        backgroundColor: '#020706',
        position: 'absolute',
        bottom: 0,
    },
    textStyle: {
        color: "#000000",
        fontWeight: '500',
        textAlign: "center",
        fontSize: 16,
      },
      chargerStationBtn:{
        borderRadius: 45,
        paddingHorizontal: 30,
        paddingVertical: 14,
        backgroundColor: "#10F4E9",
        fontSize: 12
      },
      chargerStationBtn1: {
        borderColor: '#ffff',
        borderWidth: 2,
        borderRadius: 45,
        paddingHorizontal: 30,
        paddingVertical: 14,
        fontSize: 12
      },
      textStyle1: {
        color: "#ffffff",
        fontWeight: '500',
        textAlign: "center",
        fontSize: 16,
      },
      textStyle2: {
        color: "#8D8E90",
      }
});

export default WelcomeScreen;