import React, { useState } from "react";
import {
  SafeAreaView,
  View,
  Text,
  Dimensions,
  StyleSheet,
  ScrollView,
  Pressable,
  Platform
} from "react-native";
import { TabView, SceneMap, TabBar } from 'react-native-tab-view';
import FindChargerMap from "./FindChargerMap";
import SearchCharger from "./SearchCharger";
import QRScanner from "./QRScanner";
import * as Location from 'expo-location';
import { notificationService } from "../services/notification-service";
import { useDispatch } from "react-redux";
import { getAllChargers } from "../store/actions/charging-actions";
import { NativeStackNavigationProp, NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../components/MeanderStack";
import { RouteProp } from "@react-navigation/native";
import { PanGestureHandler, State } from 'react-native-gesture-handler';
import { AppDispatch } from "../store";
import { getUserLocation } from "../components/common/utils/get-user-location";

type Props = NativeStackScreenProps<RootStackParamList, 'ChargerSelection'>;

export const ChargerSelection: React.FC<Props> = ({ navigation, route }) => {
  const [index, setIndex] = useState(0);
  const [swipeEnabled, setSwipeEnabled] = useState<boolean>(true);
  const routes = [
    { key: 'Map', title: 'Map' },
    { key: 'Search', title: 'Search' },
    { key: 'ScanQr', title: 'Scan QR' },
  ];
  const [myLocation, setMyLocation] = React.useState<Location.LocationObjectCoords | null>(null);
  const dispatch: AppDispatch = useDispatch();
  type MyRoute = { key: string, title: string };

  const { width, height } = Dimensions.get("window");

  React.useEffect(() => {
    const requestLocation = async () => {
        const userLocation = await getUserLocation();
        setMyLocation(userLocation);
    };

    const defaultIndex = routes.findIndex((idx) => idx.key === route.params.tabSelect)
    setIndex(defaultIndex);

    requestLocation();
    dispatch(getAllChargers());
  }, []);

  const renderTabBar = (props:any) => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: 'white' }}
      style={{ backgroundColor: '#1A1D21', flexDirection: 'row', justifyContent: 'space-around' }}
      renderLabel={({ route, focused, color }) => (
        <Pressable
          style={[styles.tabButton, focused && styles.tabButtonFocused]}
          onPress={() => {
            // Optionally, add any functionality you want when the tab is pressed
            setIndex(props.navigationState.routes.findIndex((r:any) => r.key === route.key));
          }}
        >
          <Text style={{ color: focused ? 'white' : '#8D8E90', fontSize: 14, fontWeight: '700' }}>{route.title}</Text>
        </Pressable>
      )}
    />
  );

  const handleMapInteraction = (isInteracting: boolean): void => {
    setSwipeEnabled(!isInteracting);
  };

  const MapRoute = () => {
      return(
        <View style={[ styles.containerBgColor, {marginBottom: 20}]}>
          {myLocation && 
          <FindChargerMap 
            navigation={navigation}
            myLocation={myLocation}
            onMapInteraction={handleMapInteraction}
          />}
        </View>
    )
  }

  const SearchRoute = () => {
  return (
    <View style={[styles.scene, styles.containerBgColor]}>
        {myLocation && <SearchCharger 
          navigation={navigation}
          myLocation={myLocation}
        />}
    </View>
    )
  };

  const ScanQrRoute = () => {
    if(index === 2){
    return(
    <View style={[styles.scene, styles.containerBgColor]}>
        <QRScanner 
          navigation={navigation}
          myLocation={myLocation}
        />
    </View>
    )} else {
      return null;
    }
  };

  return (
    <SafeAreaView>
        <View style={[styles.containerBgColor, { height: height - 65, paddingBottom: 10, 
            width: width < 430 ? width : 430,
             }]}>
            {/* <ScrollView> */}
            <TabView
                    navigationState={{index, routes}}
                    renderScene={SceneMap({
                      Map: MapRoute,
                      Search: SearchRoute,
                      ScanQr: ScanQrRoute
                    })}
                    onIndexChange={setIndex}
                    initialLayout={{ width: Dimensions.get('window').width }}
                    renderTabBar={renderTabBar}
                    swipeEnabled={swipeEnabled}
                />
            {/* </ScrollView> */}
        </View>
    </SafeAreaView>
  );
}

export default ChargerSelection;

const styles = StyleSheet.create({
 containerBgColor: {
    backgroundColor: '#1A1D21'
 },
//  scene: {
//   flex: 1
//  },
  tabButton: {
    paddingVertical: 10,
    paddingHorizontal: 25,
    borderRadius: 20,
  },
  tabButtonFocused: {
    backgroundColor: '#39BEB7',
  },
  textColor: {
    color: '#8D8E90'
  }
});
