import { MaterialIcons } from '@expo/vector-icons';
import React, {useEffect} from 'react';
import { View, Text, StyleSheet, Image, ImageSourcePropType, Pressable } from 'react-native'; 
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { ActiveChargeAttributes } from '../models/active-charges-model';
import { createEmptyOrder, OrderAttributes } from '../models/order-model';
import { useNavigation } from '@react-navigation/native';
import { RootStackParamList } from '../components/MeanderStack';
import { NativeStackNavigationProp, NativeStackScreenProps } from "@react-navigation/native-stack";

interface ActiveChargeCardProps {
    imageSource: ImageSourcePropType;
    title: string;
    subtitle: string;
    distance: string;
  }

type ChargingScreenNavigationProp = NativeStackNavigationProp<RootStackParamList, 'ChargingScreen'>;

const ActiveChargeCard = () => {
    const activeCharges: ActiveChargeAttributes[] | null = useSelector((state: RootState) => state.user.activeCharges);
    const navigation = useNavigation<ChargingScreenNavigationProp>();

    useEffect(() => {
      console.log("activeCharges: ", activeCharges);
    }, [activeCharges]);

    const getElapsedTime = (dateCreated: string) => {
      const createdDate = new Date(dateCreated);
      const now = new Date();
      const diffInMs = now.getTime() - createdDate.getTime(); // Difference in milliseconds
  
      const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60)); // Convert milliseconds to hours
      const diffInMinutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60)); // Remainder in minutes
  
      return `${diffInHours}hr ${diffInMinutes}min`;
    };

    const handleActiveCharge = () => {
        if(activeCharges !== null){  
          console.log("navigating to ChargingScreen");
          const retOrder: OrderAttributes = createEmptyOrder({id: activeCharges[0].orderId })
          navigation.navigate('ChargingScreen', { order: retOrder! })
        }
    }

    return (
        <Pressable onPress={() => handleActiveCharge()} style={styles.cardContainer}>
          <Image 
            source={require("../../assets/chargerStation.png")} 
            style={styles.image} />
    
          <View style={styles.textContainer}>
            <Text style={styles.title}>{`Active Charge`}</Text>
            {activeCharges && 
              <Text style={styles.subtitle}>{`${activeCharges[0].charger.name}: ${activeCharges[0].charger.city}, ${activeCharges[0].charger.state}`}</Text>
            }
          </View>
    
          <View style={styles.timeContainer}>
            <Text style={styles.timeText}>
              {activeCharges && getElapsedTime(activeCharges[0].dateCreated)}
            </Text>
          </View>
    
          <View style={styles.iconContainer}>
            <MaterialIcons name="keyboard-arrow-right" size={24} color="white" />
          </View>
        </Pressable>
      );
}

export default ActiveChargeCard;

const styles = StyleSheet.create({
    cardContainer: {
      position: 'absolute',
      width: 365,
      height: 73,
      left: '50%',
      marginLeft: -(365 / 2) + 0.5, // Centers the card
      top: 16,
      backgroundColor: '#0B1E1D',
      borderColor: '#39BEB7',
      borderWidth: 2,
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 4 },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      borderRadius: 16,
    },
    image: {
      position: 'absolute',
      width: 50,
      height: 50,
      left: 15,
      top: 11.5,
      borderRadius: 42,
    },
    textContainer: {
      position: 'absolute',
      width: 226,
      height: 46,
      left: 75,
      top: 13.5,
      justifyContent: 'center',
    },
    title: {
      width: 226,
      height: 22,
      fontFamily: 'PlusJakartaSans-Regular',
      fontWeight: '700',
      fontSize: 16,
      lineHeight: 22,
      color: '#FFFFFF',
    },
    subtitle: {
      width: 226,
      height: 16,
      fontFamily: 'PlusJakartaSans-Regular',
      fontWeight: '500',
      fontSize: 12,
      lineHeight: 16,
      color: '#8D8E90',
    },
    timeContainer: {
      position: 'absolute',
      width: 67,
      height: 16,
      left: 268,
      top: 28,
      flexDirection: 'row',
      alignItems: 'center',
    },
    timeText: {
      fontFamily: 'PlusJakartaSans-Regular',
      fontWeight: '400',
      fontSize: 12,
      lineHeight: 16,
      color: '#8D8E90',
    },
    iconContainer: {
      position: 'absolute',
      width: 24,
      height: 24,
      left: 335,
      top: 24.5,
    },
    iconInner: {
      width: 5.5,
      height: 11.5,
      backgroundColor: '#FFFFFF',
    },
  });
