import React, { useState } from 'react';

import { SafeAreaView, View,Text, ScrollView, StyleSheet, Dimensions, TextInput, Image, Pressable } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { FontAwesome6 } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ChargerAttributes } from '../models/charger-model';
import { RootState } from '../store';
import { getMyDistanceFromCharger, getMyDrivingTime } from '../store/actions/charging-actions';
import { chargerActions } from '../store/slices/charger-slice';
import { Button } from 'react-native-paper';
import { AppDispatch } from '../store';

const SearchCharger = ({navigation, myLocation}) => {
    const { width, height } = Dimensions.get('window');
    const [uuidInfo, setUuid] = useState<String>('');

    const [query, setQuery] = useState('');

    const [chargersWithDistance, setChargersWithDistance] = useState([]);

    // State for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const dispatch: AppDispatch = useDispatch();

    React.useEffect(() => {
        //console.log();
        //dispatch(getAllChargers());
    }, []);

    const selectedCharger: ChargerAttributes = useSelector(
        (state: RootState) => state.chargers.selectedCharger)

    const chargerList: ChargerAttributes[] = useSelector(
        (state: RootState) => state.chargers.chargers
    ) as ChargerAttributes[]

    const dropdownSet = Array.isArray(chargersWithDistance) && chargersWithDistance.length > 0 ?
                    chargersWithDistance.map((charger: ChargerAttributes) => {
        return(
            {
                id: charger.uuid?.toString(),
                title: `${charger.unit_serial}: ${charger.name}`,
                charger: charger
            }
        );
    }) : [];

    const filteredData = dropdownSet.filter( item => {
        return  item.charger?.unit_serial.toLowerCase().startsWith(query.toLowerCase()) || 
                item.charger?.name.toLowerCase().startsWith(query.toLowerCase()) || 
                item.charger?.city.toLowerCase().startsWith(query.toLowerCase())
    })

    //const {chargers} = selectedCharger;

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    React.useEffect(() => {
        // Your asynchronous code to fetch chargers and calculate distances
        let chargerDistance: any = [];
        const promises = chargerList?.map(async (chargersInfo) => {
            const loc = {
                latitude: (myLocation.latitude),
                longitude: (myLocation.longitude)
            };

            const myDistance = await getMyDistanceFromCharger(loc, chargersInfo.uuid);

            chargerDistance.push(myDistance);
            return myDistance;
        });

        Promise.all(promises)
            .then((chargers) => {
                const addDistanceToChargers = chargerList.map((charger: ChargerAttributes, index) => {
                    return {
                        ...charger,
                        totalDistance: chargerDistance[index]
                    };
                });
                const sortByDistance = addDistanceToChargers.sort((a, b) => a.totalDistance - b.totalDistance);
                setChargersWithDistance(sortByDistance);
            })
            .catch(error => {
                console.error("Error:", error);
            });
    }, [selectedCharger]);

    const searchCharger = (searchString:any) => {
        setUuid(searchString)
        setQuery(searchString)
        setCurrentPage(1);
    }

    const handleSelectCharger = async (charger: any) => {
        
        const myTime = await getMyDrivingTime(myLocation, charger.uuid);

        dispatch(chargerActions.updateSelectedCharger({selectedCharger: charger}));
        navigation.navigate("ChargerStation", {
            myDistanceFromCharger: charger.totalDistance,
            myTimeFromCharger: myTime,
        });
    }

    let newCharger;
    
    if(uuidInfo){
        if(filteredData[0]?.charger === undefined){
            newCharger = []
        }
        else{
            if( filteredData.length > 0 ){
                let updatedCharger = []

                filteredData.forEach((chargerFetch, index)=>{
                    const fetchCharger = chargerFetch.charger
                    updatedCharger.push(fetchCharger)
                })
                newCharger = updatedCharger
            }
            else {
                newCharger = [filteredData[0]?.charger].map(v => ({...v}))
            }
        }
    }
    else {
        newCharger = chargersWithDistance.map(v => ({...v}))
    }

    const backSpace = () => {
        const updatedSearch = query.slice(0, -1)
        setQuery(updatedSearch)
    }

    let chargerData = newCharger;
    // Calculate the paginated data
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = newCharger.slice(indexOfFirstItem, indexOfLastItem);

    return(
        <SafeAreaView style={[styles.safeArea, { height: height * 0.7 }]}>
            <View style={[styles.containerBgColor, {flex: 1}]}>
                {/* <ScrollView contentContainerStyle={{ flexGrow: 1 }}> */}
                    <View className='p-2' style={{ padding: 16 }}>
                        <View className='flex flex-row'>
                            <Ionicons name="search" size={22} color="white" style={styles.searchIcon}/>
                            <View style={styles.inputContainer}>
                                <TextInput
                                    placeholder='Search charger by name, city, or serial #'
                                    style={styles.input}
                                    placeholderTextColor="#a0aec0"
                                    onTextInput={searchCharger}
                                    value={query}
                                    onChange={(e) => {
                                        searchCharger(e.nativeEvent.text);
                                      }}
                                />
                                {/* <Image
                                source={require('../../assets/searchFiltering.png')}
                                style={styles.filterIcon}
                                /> */}

                            </View>

                            <Pressable onPress={backSpace}>
                                <MaterialIcons name="highlight-remove" size={38} color="white"
                                style={styles.alignCrossIcon}/>
                            </Pressable>
                        </View>
                        <View style={{height: height * 0.55}} className='pt-3'>
                        <ScrollView>
                        {
                            currentItems?.map((data,index) => {
                                return (
                                <View className='mt-4 rounded-lg' key={index} style={{backgroundColor: '#292D33'}}>
                                    <View className='flex flex-row items-center justify-between p-3'>
                                        <View className='flex flex-row items-center gap-4'>
                                            <Image
                                                source={require('../../assets/chargerStation.png')}
                                                style={{width: 50, height: 50}}
                                            />
                                            <View>
                                                {/* <Tooltip popover={
                                                    <Text style={{color:"#ffff"}}>
                                                        {data?.name ? data?.name : 'Ski and Stay'}
                                                    </Text>}
                                                    height={70}
                                                    containerStyle={styles.tooltipContainer}
                                                    backgroundColor="#0b1e1dd6"
                                                    >
                                                    <Text className='text-base font-bold text-white' style={styles.alignEllipses}>{data?.name ? data?.name : 'Ski and Stay'}</Text>
                                                </Tooltip>
                                            
                                                <Tooltip 
                                                    popover={
                                                        <Text style={{color:"#ffff"}}>
                                                            {`${data?.street_name  ? data?.street_name : "Street"} ${data?.city ? data?.city : "City"} ${data?.state ? data?.state : "State"} ${data?.country ? data?.country : "Country"}`}
                                                        </Text>}
                                                        height={70}
                                                        containerStyle={styles.tooltipContainer}
                                                        backgroundColor="#0b1e1dd6">
                                                       <Text style={[styles.textColor, styles.alignEllipses]} className='text-xs font-medium'>{`${data?.street_name  ? data?.street_name : "Street"} ${data?.city ? data?.city : "City"} ${data?.state ? data?.state : "State"} ${data?.country ? data?.country : "Country"}`}</Text>
                                                </Tooltip> */}
                                                    
                                                    <Text className='text-base font-bold text-white' style={styles.alignEllipses}>{data?.name ? data?.name : 'Ski and Stay'}</Text>
                                                    <Text style={[styles.textColor, styles.alignEllipses]} className='text-xs font-medium'>{`${data?.street_name  ? data?.street_name : "Street"} ${data?.city ? data?.city : "City"} ${data?.state ? data?.state : "State"} ${data?.country ? data?.country : "Country"}`}</Text>
                                                {/* <Text className='text-base font-bold text-white'>{data.name}</Text> */}
                                                <Text style={styles.textColor} className='text-xs font-medium'>{data?.location}</Text>   
                                            </View>
                                        </View>
                                        <Pressable 
                                            onPress={() => handleSelectCharger(data)} 
                                        >
                                            <View className='flex flex-row items-center gap-1'>
                                                <FontAwesome6 name="location-dot" size={16} color='#8D8E90' />
                                                <Text style={styles.textColor}>{data?.totalDistance} miles</Text>
                                                <MaterialIcons name="keyboard-arrow-right" size={24} color="white" />
                                                
                                            </View>
                                        </Pressable>
                                    </View>
                                    <View className='flex flex-row items-center justify-between p-3'>
                                        <View className='flex flex-row items-center gap-3'>
                                            <Image
                                                source={require('../../assets/chargerType.png')}
                                                style={{width: 33, height: 33}}
                                            />
                                            <View>
                                                <Text className='text-xs font-normal text-white' style={styles.textColor1}>{`${data?.charger_model} - ${data?.charger_type} Charger`}</Text>
                                                {/* <Text className='text-xs font-normal text-white' style={styles.textColor1}>{data.chargerLevel}</Text> */}
                                                <View className='flex flex-row items-center gap-1'>
                                                    {/* <Text className='text-lg font-semibold text-white'>{data.charging_capacity} kW .</Text> */}
                                                    <Text className='text-lg font-semibold text-white'>{`$ ${data?.price_hourly_peak}/hr (pk)`}.</Text>
                                                    {/* <Text className='font-normal' style={styles.textColor1}>Max. power</Text> */}
                                                </View>
                                            </View>
                                        </View>
                                        <View>
                                            { 
                                                data?.isOccupied === false && (data.status === 'complete' || data.status === null) ? 
                                                <Text 
                                                    style={styles.BgColor} className='w-full px-6 py-2 text-white rounded-full'
                                                    onPress={() => handleSelectCharger(data)}    
                                                >
                                                    Available
                                                </Text> :
                                                <Text style={styles.BgColor1} className='px-4 py-2 text-white rounded-full'>
                                                    Unavailable
                                                </Text>
                                            }
                                        </View>
                                    </View>
                                </View>
                                )
                            })
                        }
                        </ScrollView>
                        </View>
                    </View>
                {/* </ScrollView> */}
                {/* <View className='flex flex-row'> */}
                    <View style={styles.buttonContainer}>
                        {Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) <= 10 ? Math.ceil(filteredData.length / itemsPerPage) : 10 }, (_, i) => (
                            <Button
                                key={i + 1}
                                onPress={() => handlePageChange(i + 1)}
                                disabled={currentPage === i + 1}
                                mode={(currentPage === i + 1) ? 'contained' : 'text'}
                                compact
                                style={{backgroundColor:"white"}}
                                className='px-1 mx-1'
                            >
                                {i + 1}
                            </Button>
                        ))}
                    </View>
                {/* </View> */}
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        backgroundColor: '#1A1D21',
    },
    containerBgColor: {
        backgroundColor: '#1A1D21'
    },
    searchIcon: {
        backgroundColor: '#39BEB7',
        borderRadius: 50,
        paddingVertical: 12,
        paddingHorizontal: 13,
        marginRight: 8
    },
    inputContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 25,
        paddingHorizontal: 20,
    },
    input: {
        flex: 1,
        paddingVertical: 10,
        outlineStyle: 'none'
      },
      filterIcon: {
        height: 19,
        width: 19,
        position: 'absolute',
        right: 10,
      },
    BgColor: {
        backgroundColor: '#51B868'
    },
    BgColor1: {
        backgroundColor: '#F97777'
    },
    textColor: {
        color: '#8D8E90'
    },
    textColor1: {
        color: '#E8E8E9',
    },
    alignEllipses: {
        fontWeight: '500',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 150,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'center', // Adjust space between buttons
        //position: 'absolute',
        //bottom: 0, // Position buttons at the bottom
        width: '100%', // Make the container full width
        padding: 5, // Add some padding for better appearance
        borderRadius: 25,
        backgroundColor: '#1A1D21',
    },
    alignCrossIcon : {
        marginLeft:5, 
        marginTop:2
    },
    tooltipContainer: {
        borderRadius: 10,
        padding: 10,
        minWidth: 50,
        maxWidth: 500,
        width: 'auto',
        height: 'auto',
    }
})

export default SearchCharger;